const Vectors = {
  social: ({ dark = false }) =>
    !dark ? (
      <svg
        height="245"
        width="229"
        fill="none"
        viewBox="0 0 229 245"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M215.257 98.1624C215.257 98.1624 178.307 119.285 202.39 185.551C223.778 244.401 147.116 252.398 117.31 239.998C78.7002 223.935 65.97 168.401 31.935 178.239C-2.1 188.077 -17.6924 108.887 32.514 91.149C94.9689 69.084 63.2124 42.3918 73.2023 18.9167C80.3723 2.06839 136.956 -14.7078 158.161 21.9054C176.005 52.7154 189.265 45.6954 200.561 44.3706C216.851 42.4592 244.852 75.3798 215.257 98.1624Z"
          fill="#EEEEEE"
        />
        <path
          d="M120.039 93.6318C120.039 93.6318 61.7034 159.158 61.0293 166.425C61.0293 166.425 63.6643 167.592 66.6033 170.999C70.0053 174.942 74.0823 176.447 76.1238 175.336C79.0652 173.737 78.8125 171.536 78.8125 171.536L123.835 187.844C123.835 187.844 139.735 182.859 171.98 149.247L180.557 136.992L120.039 93.6318Z"
          fill="#929292"
        />
        <path
          d="M53.1891 103.097C53.1891 103.097 60.369 108.248 68.9498 103.393L95.9373 87.9025C95.9373 87.9025 105.126 81.121 114.576 87.6444C114.576 87.6444 105.99 108.499 123.678 112.068C123.678 112.068 140.94 119.979 150.074 128.1C154.666 132.186 164.489 139.116 169.16 143.029C170.413 144.078 172.977 145.646 174.278 147.218C176.084 149.401 179.537 154.161 173.997 158.779C173.997 158.779 170.305 163.116 162.908 158.551C156.034 154.309 144.048 144.468 136.784 139.891C136.389 139.643 134.589 139.276 137.1 141.726C140.198 144.745 154.665 155.439 154.665 155.439C154.665 155.439 160.077 159.197 161.362 161.104C162.491 162.777 164.23 166.451 160.994 170.44C160.994 170.44 156.398 176.498 148.519 171.684L130.429 159.056C130.429 159.056 126.582 156.573 129.675 160.198C129.675 160.198 148.034 173.097 148.756 175.06C150.814 180.655 146.832 187.851 137.885 184.377L117.158 173.044C117.158 173.044 115.264 172.767 116.814 174.279C116.814 174.279 136.045 184.203 137.378 185.293C139.12 186.719 134.889 193.441 126.123 190.748C113.618 186.91 88.3444 180 76.1231 175.336C71.8232 173.696 62.9903 169.313 59.5859 167.253C59.5859 167.253 45.9299 158.659 27.2298 158.301L40.7024 100.876L53.1891 103.097Z"
          fill="#CECECE"
        />
        <path
          d="M74.0777 179.207C74.0777 179.207 78.0266 173.99 80.4677 172.104C85.4199 168.277 94.3553 171.985 94.7894 176.675C94.7894 176.675 96.7157 181.283 88.7274 188.079C87.2505 189.334 82.5578 192.318 75.696 186.679C75.6959 186.68 71.6021 182.915 74.0777 179.207Z"
          fill="#929292"
        />
        <path
          d="M81.9916 132.838C81.9916 132.838 93.4338 141.9 105.279 114.942C105.279 114.942 111.581 117.138 121.372 111.46L123.412 108.287C123.412 108.287 103.526 110.404 103.614 110.795C103.702 111.186 81.9916 132.838 81.9916 132.838Z"
          fill="#929292"
        />
        <path
          d="M91.093 189.409C90.9523 185.949 95.8021 179.602 97.9248 177.362C100.426 174.722 110.275 172.198 111.419 182.683C111.419 182.683 110.808 190.031 105.337 194.22C102.737 196.212 91.3908 196.743 91.093 189.409Z"
          fill="#929292"
        />
        <path
          d="M113.169 184.176C114.845 181.51 123.154 180.238 123.339 187.894C123.409 190.793 121.899 195.571 117.594 197.466C114.464 198.842 106.204 196.601 107.774 193.245C108.853 190.937 109.38 190.2 113.169 184.176Z"
          fill="#929292"
        />
        <path
          d="M59.3972 172.371C60.1191 170.784 61.3121 169.263 63.6942 167.343C65.1356 166.183 69.1856 165.732 71.6211 167.253C73.3692 168.346 76.8153 170.906 76.043 173.183C74.5737 177.509 72.083 183.371 66.6903 181.677C66.69 181.677 56.1785 179.446 59.3972 172.371Z"
          fill="#929292"
        />
        <path
          d="M51.0357 97.4467L40.0294 168.996L4.6881 162.195L20.351 89.3071L51.0357 97.4467Z"
          fill="#929292"
        />
        <path
          d="M63.2889 169.96C63.2889 169.96 61.561 174.863 64.629 176.922C64.629 176.922 68.577 181.66 73.464 173.834C74.2784 172.53 72.4575 170.228 69.2321 169.367C69.2322 169.367 66.1764 168.191 63.2889 169.96Z"
          fill="#E3E3E3"
        />
        <path
          d="M78.9286 178.776C78.9286 178.776 80.9402 174.274 82.6487 173.582C84.4082 172.87 89.6942 172.754 91.9152 177.947C92.2973 178.84 90.9176 180.705 90.2966 181.693C89.1519 183.518 86.1965 186.25 82.7606 184.374C82.7603 184.373 78.3648 182.786 78.9286 178.776Z"
          fill="#E3E3E3"
        />
        <path
          d="M96.915 182.63C97.3923 181.322 99.2446 178.938 100.931 178.77C103.704 178.494 110.108 180.55 108.416 184.651C108.416 184.651 106.963 188.127 103.845 188.938C103.845 188.938 98.4373 190.877 96.4371 185.845C96.1362 185.09 96.6382 183.393 96.915 182.63Z"
          fill="#E3E3E3"
        />
        <path
          d="M111.598 190.493C112.397 192.484 115.821 194.543 117.911 192.645C118.759 191.876 120.504 189.306 120.6 188.167C120.76 186.24 116.968 182.172 113.646 185.96C112.863 186.854 111.156 189.389 111.598 190.493Z"
          fill="#E3E3E3"
        />
        <path
          d="M127.857 85.7873C127.857 85.7873 116.292 82.1402 107.447 91.0213L92.6202 100.099C92.6202 100.099 86.2965 102.196 84.3402 110.657L77.6052 124.661C77.6052 124.661 72.1639 134.269 88.0321 132.953C88.0321 132.953 97.1142 129.115 104.325 114.531L104.274 112.67C104.274 112.67 105.302 114.966 110.543 114.044C110.543 114.044 115.956 112.171 123.442 109.691C123.442 109.691 124.657 111.17 131.848 115.119C131.848 115.119 149.431 124.319 153.518 129.224L174.582 145.255C174.582 145.255 177.852 143.949 185.989 131.296L204.143 124.843L185.097 72.8809C185.097 72.8809 156.099 84.2372 147.584 85.0862L127.857 85.7873Z"
          fill="#7E7E7E"
        />
        <path
          d="M172.424 74.1447L197.512 133.203L224.209 122.842L197.983 63.0637L172.424 74.1447Z"
          fill="#CECECE"
        />
        <path
          d="M80.9504 117.705C80.9504 117.705 87.3235 115.292 89.2027 119.704C89.2027 119.704 87.2666 127.57 85.0881 129.314C83.4041 130.664 77.8131 131.216 76.7136 128.338C76.7136 128.338 76.022 125.373 80.9504 117.705Z"
          fill="#E3E3E3"
        />
        <path
          d="M138.414 118.738C138.414 118.738 150.27 123.608 162.246 114.014C162.246 114.014 154.019 125.101 142.945 121.415L138.414 118.738Z"
          fill="#929292"
        />
        <path
          d="M28.0604 155.214C28.1378 155.99 27.9836 156.77 27.6173 157.458C27.2511 158.145 26.6892 158.709 26.0027 159.077C25.3162 159.445 24.5359 159.601 23.7606 159.526C22.9853 159.45 22.2497 159.147 21.647 158.653C21.0442 158.16 20.6013 157.499 20.3743 156.754C20.1473 156.009 20.1464 155.213 20.3716 154.467C20.5969 153.721 21.0382 153.059 21.6398 152.564C22.2413 152.07 22.9762 151.764 23.7513 151.687C24.7904 151.584 25.8281 151.897 26.6361 152.559C27.4442 153.22 27.9565 154.175 28.0604 155.214V155.214Z"
          fill="white"
        />
        <path
          d="M211.284 117.091C211.361 117.866 211.207 118.647 210.841 119.334C210.474 120.021 209.913 120.584 209.226 120.952C208.54 121.32 207.76 121.476 206.985 121.401C206.209 121.326 205.474 121.022 204.872 120.529C204.269 120.035 203.826 119.374 203.599 118.629C203.372 117.885 203.371 117.089 203.596 116.343C203.822 115.598 204.263 114.936 204.864 114.441C205.465 113.946 206.2 113.641 206.975 113.564C207.49 113.512 208.009 113.563 208.504 113.712C208.999 113.862 209.46 114.107 209.86 114.435C210.26 114.763 210.592 115.166 210.836 115.622C211.081 116.077 211.233 116.577 211.284 117.091V117.091Z"
          fill="white"
        />
      </svg>
    ) : (
      <svg
        height="245"
        width="229"
        fill="none"
        viewBox="0 0 229 245"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M215.257 98.1627C215.257 98.1627 178.307 119.286 202.39 185.551C223.778 244.401 147.116 252.398 117.31 239.998C78.7002 223.936 65.97 168.402 31.935 178.239C-2.09998 188.077 -17.6923 108.887 32.514 91.1493C94.9689 69.0843 63.2124 42.3921 73.2023 18.9169C80.3723 2.06863 136.956 -14.7075 158.161 21.9057C176.005 52.7157 189.265 45.6957 200.561 44.3709C216.851 42.4594 244.852 75.3801 215.257 98.1627Z"
          fill="#232323"
        />
        <path
          d="M120.04 93.6328C120.04 93.6328 61.7036 159.159 61.0295 166.426C61.0295 166.426 63.6646 167.593 66.6035 171C70.0055 174.943 74.0825 176.448 76.124 175.337C79.0654 173.738 78.8128 171.537 78.8128 171.537L123.835 187.845C123.835 187.845 139.735 182.86 171.98 149.248L180.557 136.993L120.04 93.6328Z"
          fill="#676767"
        />
        <path
          d="M53.1893 103.097C53.1893 103.097 60.3692 108.248 68.9499 103.393L95.9374 87.9025C95.9374 87.9025 105.126 81.121 114.576 87.6444C114.576 87.6444 105.99 108.499 123.678 112.068C123.678 112.068 140.94 119.979 150.074 128.1C154.667 132.186 164.489 139.116 169.16 143.029C170.413 144.078 172.977 145.646 174.278 147.218C176.085 149.401 179.537 154.161 173.997 158.779C173.997 158.779 170.305 163.116 162.909 158.551C156.034 154.309 144.048 144.468 136.784 139.891C136.39 139.643 134.589 139.276 137.101 141.726C140.198 144.745 154.666 155.439 154.666 155.439C154.666 155.439 160.077 159.197 161.362 161.104C162.492 162.777 164.23 166.451 160.994 170.44C160.994 170.44 156.398 176.498 148.519 171.684L130.429 159.056C130.429 159.056 126.582 156.573 129.675 160.198C129.675 160.198 148.034 173.097 148.756 175.06C150.814 180.655 146.832 187.851 137.885 184.377L117.158 173.044C117.158 173.044 115.264 172.767 116.815 174.279C116.815 174.279 136.046 184.203 137.378 185.293C139.12 186.719 134.889 193.441 126.123 190.748C113.618 186.91 88.3446 180 76.1232 175.336C71.8233 173.696 62.9904 169.313 59.586 167.253C59.586 167.253 45.93 158.659 27.23 158.301L40.7025 100.876L53.1893 103.097Z"
          fill="#A5A5A5"
        />
        <path
          d="M74.0779 179.207C74.0779 179.207 78.0268 173.99 80.4679 172.104C85.4201 168.277 94.3555 171.985 94.7896 176.675C94.7896 176.675 96.7159 181.283 88.7276 188.079C87.2507 189.334 82.558 192.318 75.6962 186.679C75.6961 186.68 71.6023 182.915 74.0779 179.207Z"
          fill="#676767"
        />
        <path
          d="M81.9917 132.838C81.9917 132.838 93.4339 141.9 105.279 114.942C105.279 114.942 111.581 117.138 121.372 111.46L123.412 108.287C123.412 108.287 103.526 110.404 103.614 110.795C103.702 111.186 81.9917 132.838 81.9917 132.838Z"
          fill="#676767"
        />
        <path
          d="M91.0931 189.409C90.9524 185.95 95.8022 179.603 97.9248 177.362C100.426 174.722 110.275 172.199 111.419 182.683C111.419 182.683 110.809 190.031 105.338 194.22C102.737 196.213 91.3908 196.744 91.0931 189.409Z"
          fill="#676767"
        />
        <path
          d="M113.169 184.177C114.845 181.511 123.154 180.239 123.339 187.895C123.409 190.794 121.899 195.572 117.594 197.467C114.465 198.843 106.204 196.602 107.774 193.246C108.853 190.938 109.38 190.201 113.169 184.177Z"
          fill="#676767"
        />
        <path
          d="M59.3973 172.372C60.1193 170.785 61.3122 169.263 63.6944 167.344C65.1357 166.183 69.1857 165.733 71.6213 167.254C73.3694 168.346 76.8155 170.906 76.0431 173.183C74.5739 177.509 72.0831 183.371 66.6905 181.677C66.6902 181.677 56.1786 179.446 59.3973 172.372Z"
          fill="#676767"
        />
        <path
          d="M51.0358 97.4482L40.0296 168.998L4.68823 162.196L20.3511 89.3086L51.0358 97.4482Z"
          fill="#676767"
        />
        <path
          d="M63.289 169.961C63.289 169.961 61.5612 174.863 64.6291 176.923C64.6291 176.923 68.5771 181.661 73.4641 173.835C74.2785 172.531 72.4576 170.228 69.2322 169.368C69.2323 169.368 66.1765 168.191 63.289 169.961Z"
          fill="#E3E3E3"
        />
        <path
          d="M78.9289 178.777C78.9289 178.777 80.9404 174.275 82.6489 173.583C84.4084 172.871 89.6944 172.755 91.9154 177.948C92.2975 178.841 90.9178 180.706 90.2968 181.694C89.1521 183.519 86.1967 186.251 82.7608 184.375C82.7605 184.374 78.365 182.787 78.9289 178.777Z"
          fill="#E3E3E3"
        />
        <path
          d="M96.9151 182.631C97.3924 181.323 99.2447 178.939 100.931 178.771C103.704 178.495 110.108 180.551 108.416 184.652C108.416 184.652 106.963 188.128 103.845 188.939C103.845 188.939 98.4374 190.878 96.4372 185.846C96.1363 185.091 96.6383 183.394 96.9151 182.631Z"
          fill="#E3E3E3"
        />
        <path
          d="M111.598 190.494C112.397 192.485 115.821 194.544 117.912 192.646C118.759 191.877 120.504 189.306 120.6 188.167C120.76 186.24 116.968 182.172 113.647 185.961C112.863 186.855 111.156 189.39 111.598 190.494Z"
          fill="#E3E3E3"
        />
        <path
          d="M127.857 85.7873C127.857 85.7873 116.293 82.1402 107.447 91.0213L92.6203 100.099C92.6203 100.099 86.2966 102.196 84.3403 110.657L77.6053 124.661C77.6053 124.661 72.1641 134.269 88.0323 132.953C88.0323 132.953 97.1143 129.115 104.325 114.531L104.274 112.67C104.274 112.67 105.302 114.966 110.543 114.044C110.543 114.044 115.956 112.171 123.442 109.691C123.442 109.691 124.657 111.17 131.849 115.119C131.849 115.119 149.431 124.319 153.518 129.224L174.582 145.255C174.582 145.255 177.852 143.949 185.989 131.296L204.143 124.843L185.097 72.8809C185.097 72.8809 156.099 84.2372 147.585 85.0862L127.857 85.7873Z"
          fill="#7E7E7E"
        />
        <path
          d="M172.424 74.1454L197.512 133.204L224.209 122.843L197.983 63.0645L172.424 74.1454Z"
          fill="#A5A5A5"
        />
        <path
          d="M80.9504 117.705C80.9504 117.705 87.3236 115.293 89.2028 119.705C89.2028 119.705 87.2666 127.571 85.0881 129.314C83.4041 130.664 77.8131 131.217 76.7136 128.339C76.7136 128.339 76.022 125.373 80.9504 117.705Z"
          fill="#E3E3E3"
        />
        <path
          d="M138.415 118.739C138.415 118.739 150.27 123.61 162.246 114.016C162.246 114.016 154.019 125.102 142.946 121.416L138.415 118.739Z"
          fill="#676767"
        />
        <path
          d="M28.0604 155.215C28.1378 155.99 27.9836 156.771 27.6174 157.458C27.2511 158.145 26.6892 158.709 26.0027 159.077C25.3162 159.445 24.5359 159.601 23.7606 159.526C22.9853 159.451 22.2498 159.147 21.647 158.654C21.0442 158.16 20.6013 157.499 20.3743 156.754C20.1473 156.009 20.1464 155.213 20.3716 154.467C20.5969 153.722 21.0382 153.06 21.6398 152.565C22.2414 152.07 22.9762 151.765 23.7513 151.687C24.7905 151.584 25.8281 151.897 26.6362 152.559C27.4442 153.22 27.9565 154.176 28.0604 155.215V155.215Z"
          fill="black"
        />
        <path
          d="M211.284 117.092C211.362 117.867 211.207 118.648 210.841 119.335C210.475 120.022 209.913 120.585 209.226 120.953C208.54 121.321 207.76 121.477 206.985 121.402C206.21 121.326 205.474 121.023 204.872 120.529C204.269 120.036 203.826 119.375 203.599 118.63C203.372 117.885 203.371 117.09 203.597 116.344C203.822 115.599 204.263 114.937 204.864 114.442C205.466 113.947 206.2 113.642 206.975 113.564C207.49 113.513 208.009 113.564 208.505 113.713C209 113.863 209.46 114.108 209.861 114.436C210.261 114.763 210.592 115.167 210.837 115.622C211.081 116.078 211.233 116.578 211.284 117.092V117.092Z"
          fill="black"
        />
      </svg>
    ),
  connectivity: ({ dark = false }) =>
    !dark ? (
      <svg
        height="212"
        width="286"
        fill="none"
        className="small_size"
        viewBox="0 0 286 212"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M83.5959 35.1636C83.5959 35.1636 115.764 59.5751 164.668 13.9301C208.1 -26.6061 243.563 37.3509 243.811 67.9788C244.132 107.654 200.379 139.4 221.613 165.459C242.846 191.518 179.504 234.551 145.366 197.309C102.899 150.982 91.3941 188.623 67.1883 188.623C49.8155 188.623 14.1475 145.456 38.2337 113.341C58.502 86.3166 47.4481 77.3478 42.095 67.9788C34.3732 54.4667 52.7111 17.7909 83.5959 35.1636Z"
          fill="#EEEEEE"
        />
        <path
          d="M212.741 89.1091C214.47 89.7515 216.36 89.7943 218.158 89.3816C225.69 87.6527 251.59 83.075 247.089 103.145C246.109 107.127 230.94 129.426 252.542 137.567C254.125 138.171 255.799 138.5 257.493 138.542C264.144 138.679 277.324 137.108 282.84 131.493C289.504 124.71 282.224 127.927 282.224 127.927C282.224 127.927 256.891 138.982 247.942 127.253C246.947 125.948 246.313 124.053 246.131 122.421C245.816 119.592 247.072 115.854 248.722 112.599C250.956 108.195 261.678 88.381 238.398 82.609C233.036 81.3319 225.247 80.1847 215.203 84.4132C209.345 86.8795 210.643 88.3292 212.741 89.1091Z"
          fill="#DCDCDC"
        />
        <path
          d="M217.925 81.7121L211.183 84.2357C208.855 85.1068 207.674 87.6999 208.545 90.0275L208.88 90.9205C209.751 93.2481 212.344 94.4288 214.671 93.5577L221.414 91.0341C223.742 90.163 224.923 87.5699 224.051 85.2423L223.717 84.3493C222.846 82.0217 220.253 80.841 217.925 81.7121Z"
          fill="#929292"
        />
        <path
          d="M176.536 89.0142L154.299 97.3368C153.135 97.7724 152.545 99.0689 152.98 100.233L153.068 100.467C153.504 101.631 154.8 102.221 155.964 101.785L178.201 93.4628C179.365 93.0272 179.955 91.7307 179.52 90.5669L179.432 90.3328C178.997 89.169 177.7 88.5787 176.536 89.0142Z"
          fill="white"
        />
        <path
          d="M182.743 105.597L160.505 113.919C159.342 114.355 158.751 115.651 159.187 116.815L159.274 117.049C159.71 118.213 161.006 118.803 162.17 118.368L184.408 110.045C185.572 109.61 186.162 108.313 185.726 107.149L185.639 106.915C185.203 105.751 183.907 105.161 182.743 105.597Z"
          fill="white"
        />
        <path
          d="M203.273 77.6979L185.25 84.4432C182.923 85.3143 181.742 87.9074 182.613 90.235L188.767 106.676C189.638 109.004 192.231 110.184 194.559 109.313L212.581 102.568C214.909 101.697 216.09 99.1038 215.219 96.7762L209.065 80.3351C208.194 78.0075 205.601 76.8268 203.273 77.6979Z"
          fill="#C2C2C2"
        />
        <path
          d="M187.112 82.0114L169.09 88.7567C166.762 89.6278 165.581 92.2209 166.452 94.5485L173.793 114.163C174.665 116.491 177.258 117.672 179.585 116.801L197.608 110.055C199.936 109.184 201.117 106.591 200.245 104.264L192.904 84.6485C192.033 82.3209 189.44 81.1402 187.112 82.0114Z"
          fill="#CECECE"
        />
        <path
          d="M189.934 78.0303L189.792 78.0832C188.241 78.664 187.454 80.3927 188.034 81.9444L198.319 109.423C198.899 110.975 200.628 111.762 202.18 111.181L202.321 111.128C203.873 110.547 204.66 108.819 204.079 107.267L193.795 79.7884C193.214 78.2367 191.486 77.4496 189.934 78.0303Z"
          fill="#7E7E7E"
        />
        <path
          d="M6.15891 95.5993C6.15891 95.5993 42.3551 85.4272 37.1 108.863C36.1874 112.571 23.0506 131.664 38.6986 140.832C42.0586 142.8 45.9361 143.672 49.8299 143.652C56.8114 143.615 67.8791 142.272 72.8512 137.211C79.5148 130.427 72.235 133.644 72.235 133.644C72.235 133.644 51.4469 142.195 40.7522 135.379C37.1005 133.051 35.4452 128.545 36.4369 124.33C36.9437 122.236 37.7155 120.215 38.7337 118.317C41.0596 113.96 51.6895 94.0982 28.4095 88.3262C23.0467 87.0491 15.2576 85.9019 5.21436 90.1304C-4.82889 94.3589 6.15891 95.5993 6.15891 95.5993Z"
          fill="#DCDCDC"
        />
        <path
          d="M77.7183 128.4L70.9756 130.924C68.648 131.795 67.4673 134.388 68.3384 136.715L68.6726 137.609C69.5438 139.936 72.1369 141.117 74.4645 140.246L81.2072 137.722C83.5348 136.851 84.7155 134.258 83.8444 131.93L83.5102 131.037C82.639 128.71 80.0459 127.529 77.7183 128.4Z"
          fill="#929292"
        />
        <path
          d="M89.1104 117.306L79.1173 121.046C76.7897 121.917 75.609 124.51 76.4801 126.838L81.2988 139.713C82.1699 142.041 84.763 143.221 87.0906 142.35L97.0837 138.61C99.4113 137.739 100.592 135.146 99.7209 132.818L94.9023 119.943C94.0311 117.616 91.4381 116.435 89.1104 117.306Z"
          fill="#C2C2C2"
        />
        <path
          d="M105.747 107.394L90.8443 112.971C88.5167 113.842 87.336 116.435 88.2071 118.763L95.5483 138.378C96.4194 140.705 99.0125 141.886 101.34 141.015L116.243 135.438C118.57 134.566 119.751 131.973 118.88 129.646L111.539 110.031C110.668 107.703 108.074 106.522 105.747 107.394Z"
          fill="#CECECE"
        />
        <path
          d="M108.903 103.331L108.762 103.384C107.21 103.965 106.423 105.693 107.004 107.245L117.288 134.724C117.869 136.275 119.598 137.063 121.149 136.482L121.291 136.429C122.842 135.848 123.63 134.119 123.049 132.568L112.765 105.089C112.184 103.537 110.455 102.75 108.903 103.331Z"
          fill="#7E7E7E"
        />
        <path
          d="M146.958 85.8252C146.421 85.7656 145.929 85.5001 145.584 85.0847C145.239 84.6692 145.069 84.1361 145.109 83.5977L146.221 63.2877C146.287 62.0745 147.312 61.1527 148.474 61.26L150.094 61.41C151.257 61.5172 152.089 62.61 151.927 63.8146L149.194 83.9746C149.131 84.5116 148.864 85.0037 148.447 85.3484C148.031 85.6931 147.497 85.8639 146.958 85.8252V85.8252Z"
          fill="#8B8B8B"
        />
        <path
          d="M137.357 87.5765C137.19 87.6255 137.015 87.6412 136.842 87.6226C136.669 87.6041 136.501 87.5516 136.349 87.4682C136.196 87.3849 136.061 87.2722 135.952 87.1368C135.843 87.0013 135.761 86.8457 135.712 86.6787L131.741 74.0448C131.626 73.6855 131.658 73.2951 131.83 72.9592C132.002 72.6233 132.3 72.3693 132.659 72.2531L133.687 71.9429C133.864 71.8914 134.049 71.8771 134.231 71.9007C134.413 71.9244 134.589 71.9856 134.746 72.0805C134.903 72.1753 135.039 72.3018 135.145 72.4519C135.251 72.6019 135.325 72.7724 135.362 72.9524L138.305 85.8966C138.387 86.2446 138.335 86.6106 138.159 86.9219C137.984 87.2332 137.697 87.4669 137.357 87.5765V87.5765Z"
          fill="#8B8B8B"
        />
        <path
          d="M131.999 94.8621C131.287 95.7437 129.856 95.7332 128.755 94.8383L109.199 78.95C108.031 78.001 107.687 76.43 108.441 75.4955L109.493 74.1924C110.248 73.2574 111.798 73.334 112.904 74.3609L131.407 91.5526C132.449 92.5207 132.71 93.9806 131.999 94.8621Z"
          fill="#8B8B8B"
        />
        <path
          d="M141.038 135.899C141.542 136.094 141.95 136.476 142.178 136.966C142.405 137.456 142.433 138.015 142.257 138.525L135.995 157.877C135.621 159.033 134.395 159.662 133.298 159.262L131.77 158.704C130.673 158.303 130.147 157.033 130.612 155.911L138.403 137.117C138.6 136.614 138.985 136.206 139.476 135.979C139.967 135.752 140.526 135.724 141.038 135.899V135.899Z"
          fill="#8B8B8B"
        />
        <path
          d="M150.768 136.659C150.942 136.654 151.115 136.683 151.278 136.746C151.44 136.808 151.589 136.901 151.715 137.021C151.842 137.14 151.943 137.284 152.014 137.443C152.085 137.601 152.124 137.773 152.129 137.947L152.741 151.176C152.761 151.553 152.631 151.922 152.379 152.203C152.127 152.484 151.773 152.653 151.396 152.674L150.323 152.711C150.139 152.716 149.957 152.682 149.787 152.613C149.616 152.544 149.463 152.44 149.335 152.308C149.207 152.176 149.107 152.019 149.043 151.847C148.979 151.674 148.951 151.491 148.962 151.307L149.423 138.041C149.432 137.684 149.576 137.343 149.825 137.087C150.074 136.831 150.411 136.678 150.768 136.659V136.659Z"
          fill="#8B8B8B"
        />
        <path
          d="M157.809 130.983C158.722 130.312 160.103 130.688 160.939 131.834L175.789 152.189C176.676 153.406 176.608 155.012 175.639 155.723L174.289 156.714C173.32 157.426 171.841 156.955 171.034 155.68L157.534 134.333C156.775 133.132 156.896 131.653 157.809 130.983Z"
          fill="#8B8B8B"
        />
      </svg>
    ) : (
      <svg
        height="212"
        width="286"
        fill="none"
        viewBox="0 0 286 212"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M83.5961 35.1631C83.5961 35.1631 115.764 59.5746 164.669 13.9296C208.1 -26.6065 243.563 37.3504 243.811 67.9783C244.133 107.653 200.379 139.399 221.613 165.459C242.846 191.518 179.504 234.55 145.366 197.309C102.899 150.981 91.3943 188.622 67.1885 188.622C49.8157 188.622 14.1476 145.455 38.2339 113.34C58.5022 86.3161 47.4482 77.3473 42.0952 67.9783C34.3733 54.4662 52.7113 17.7904 83.5961 35.1631Z"
          fill="#232323"
        />
        <path
          d="M212.741 89.1076C214.47 89.75 216.36 89.7928 218.158 89.3802C225.69 87.6513 251.59 83.0735 247.089 103.143C246.109 107.125 230.94 129.425 252.542 137.565C254.125 138.169 255.799 138.499 257.493 138.54C264.144 138.677 277.324 137.107 282.84 131.492C289.504 124.709 282.224 127.925 282.224 127.925C282.224 127.925 256.891 138.98 247.943 127.252C246.947 125.947 246.313 124.051 246.131 122.42C245.816 119.591 247.072 115.852 248.723 112.598C250.956 108.193 261.678 88.3795 238.399 82.6075C233.036 81.3304 225.247 80.1832 215.203 84.4117C209.345 86.878 210.643 88.3277 212.741 89.1076Z"
          fill="#9F9F9F"
        />
        <path
          d="M217.926 81.7116L211.183 84.2352C208.855 85.1063 207.674 87.6994 208.546 90.027L208.88 90.92C209.751 93.2476 212.344 94.4283 214.672 93.5572L221.414 91.0336C223.742 90.1625 224.923 87.5694 224.052 85.2418L223.717 84.3488C222.846 82.0212 220.253 80.8405 217.926 81.7116Z"
          fill="#676767"
        />
        <path
          d="M176.536 89.0138L154.299 97.3363C153.135 97.7719 152.545 99.0684 152.981 100.232L153.068 100.466C153.504 101.63 154.8 102.22 155.964 101.785L178.201 93.4623C179.365 93.0267 179.956 91.7302 179.52 90.5664L179.432 90.3323C178.997 89.1685 177.7 88.5782 176.536 89.0138Z"
          fill="black"
        />
        <path
          d="M182.743 105.596L160.505 113.918C159.342 114.354 158.751 115.65 159.187 116.814L159.274 117.048C159.71 118.212 161.007 118.802 162.17 118.367L184.408 110.044C185.572 109.609 186.162 108.312 185.726 107.148L185.639 106.914C185.203 105.75 183.907 105.16 182.743 105.596Z"
          fill="black"
        />
        <path
          d="M203.274 77.6969L185.251 84.4422C182.923 85.3133 181.742 87.9064 182.613 90.234L188.767 106.675C189.638 109.003 192.231 110.184 194.559 109.312L212.582 102.567C214.909 101.696 216.09 99.1029 215.219 96.7753L209.065 80.3341C208.194 78.0065 205.601 76.8258 203.274 77.6969Z"
          fill="#C2C2C2"
        />
        <path
          d="M187.112 82.0094L169.09 88.7547C166.762 89.6258 165.581 92.2189 166.452 94.5465L173.793 114.162C174.665 116.489 177.258 117.67 179.585 116.799L197.608 110.053C199.936 109.182 201.117 106.589 200.245 104.262L192.904 84.6466C192.033 82.319 189.44 81.1383 187.112 82.0094Z"
          fill="#A5A5A5"
        />
        <path
          d="M189.934 78.0284L189.793 78.0813C188.241 78.662 187.454 80.3907 188.034 81.9425L198.319 109.421C198.899 110.973 200.628 111.76 202.18 111.179L202.321 111.126C203.873 110.545 204.66 108.817 204.079 107.265L193.795 79.7865C193.214 78.2347 191.486 77.4476 189.934 78.0284Z"
          fill="#7E7E7E"
        />
        <path
          d="M6.15899 95.5973C6.15899 95.5973 42.3552 85.4252 37.1001 108.861C36.1875 112.569 23.0506 131.662 38.6986 140.83C42.0586 142.798 45.9361 143.67 49.83 143.65C56.8114 143.613 67.8792 142.27 72.8512 137.209C79.5148 130.425 72.235 133.642 72.235 133.642C72.235 133.642 51.447 142.193 40.7523 135.377C37.1005 133.05 35.4453 128.543 36.4369 124.328C36.9438 122.234 37.7156 120.213 38.7337 118.315C41.0596 113.958 51.6895 94.0963 28.4095 88.3243C23.0467 87.0472 15.2577 85.9 5.21444 90.1285C-4.82881 94.357 6.15899 95.5973 6.15899 95.5973Z"
          fill="#9F9F9F"
        />
        <path
          d="M77.7185 128.399L70.9757 130.923C68.6481 131.794 67.4674 134.387 68.3386 136.715L68.6728 137.608C69.5439 139.935 72.137 141.116 74.4646 140.245L81.2074 137.721C83.535 136.85 84.7157 134.257 83.8446 131.929L83.5103 131.036C82.6392 128.709 80.0461 127.528 77.7185 128.399Z"
          fill="#676767"
        />
        <path
          d="M89.1105 117.304L79.1173 121.044C76.7897 121.915 75.609 124.508 76.4802 126.836L81.2988 139.711C82.1699 142.038 84.763 143.219 87.0906 142.348L97.0838 138.608C99.4114 137.737 100.592 135.144 99.7209 132.816L94.9023 119.941C94.0312 117.613 91.4381 116.433 89.1105 117.304Z"
          fill="#C2C2C2"
        />
        <path
          d="M105.747 107.392L90.8444 112.97C88.5168 113.841 87.3361 116.434 88.2072 118.761L95.5484 138.376C96.4195 140.704 99.0126 141.885 101.34 141.014L116.243 135.436C118.57 134.565 119.751 131.972 118.88 129.644L111.539 110.029C110.668 107.702 108.075 106.521 105.747 107.392Z"
          fill="#A5A5A5"
        />
        <path
          d="M108.903 103.329L108.762 103.382C107.21 103.963 106.423 105.692 107.004 107.243L117.288 134.722C117.869 136.273 119.598 137.061 121.149 136.48L121.291 136.427C122.842 135.846 123.63 134.117 123.049 132.566L112.765 105.087C112.184 103.536 110.455 102.748 108.903 103.329Z"
          fill="#7E7E7E"
        />
        <path
          d="M146.958 85.8237C146.421 85.7641 145.929 85.4987 145.584 85.0832C145.239 84.6677 145.069 84.1347 145.109 83.5962L146.221 63.2862C146.287 62.073 147.312 61.1512 148.474 61.2585L150.094 61.4085C151.257 61.5157 152.089 62.6085 151.927 63.8131L149.194 83.9732C149.131 84.5102 148.864 85.0023 148.447 85.347C148.031 85.6917 147.497 85.8624 146.958 85.8237V85.8237Z"
          fill="#8B8B8B"
        />
        <path
          d="M137.357 87.5755C137.19 87.6246 137.015 87.6402 136.842 87.6217C136.669 87.6031 136.501 87.5506 136.349 87.4673C136.196 87.3839 136.061 87.2713 135.952 87.1358C135.843 87.0003 135.761 86.8447 135.712 86.6778L131.741 74.0439C131.626 73.6846 131.658 73.2941 131.83 72.9582C132.002 72.6223 132.3 72.3684 132.659 72.2521L133.687 71.9419C133.864 71.8904 134.049 71.8761 134.231 71.8998C134.413 71.9234 134.589 71.9846 134.746 72.0795C134.903 72.1744 135.039 72.3008 135.145 72.4509C135.251 72.601 135.325 72.7714 135.362 72.9514L138.305 85.8957C138.387 86.2436 138.335 86.6097 138.159 86.921C137.984 87.2323 137.697 87.466 137.357 87.5755V87.5755Z"
          fill="#8B8B8B"
        />
        <path
          d="M131.999 94.8612C131.287 95.7427 129.856 95.7322 128.755 94.8373L109.199 78.9491C108.031 78 107.687 76.4291 108.441 75.4946L109.493 74.1914C110.248 73.2564 111.798 73.3331 112.904 74.36L131.407 91.5516C132.449 92.5197 132.71 93.9796 131.999 94.8612Z"
          fill="#8B8B8B"
        />
        <path
          d="M141.038 135.899C141.542 136.094 141.95 136.476 142.178 136.966C142.405 137.456 142.433 138.014 142.257 138.525L135.995 157.877C135.621 159.033 134.395 159.662 133.298 159.262L131.77 158.703C130.673 158.303 130.147 157.033 130.612 155.91L138.403 137.117C138.6 136.613 138.985 136.206 139.476 135.979C139.967 135.752 140.526 135.723 141.038 135.899V135.899Z"
          fill="#8B8B8B"
        />
        <path
          d="M150.768 136.659C150.942 136.654 151.115 136.683 151.278 136.746C151.44 136.808 151.589 136.901 151.715 137.021C151.841 137.14 151.943 137.284 152.014 137.443C152.085 137.601 152.124 137.773 152.129 137.947L152.741 151.176C152.761 151.553 152.631 151.922 152.379 152.203C152.127 152.484 151.773 152.653 151.396 152.674L150.323 152.711C150.139 152.716 149.957 152.682 149.786 152.613C149.616 152.544 149.462 152.44 149.335 152.308C149.207 152.176 149.107 152.019 149.043 151.847C148.979 151.674 148.951 151.491 148.962 151.307L149.423 138.041C149.432 137.684 149.576 137.343 149.825 137.087C150.074 136.831 150.411 136.678 150.768 136.659V136.659Z"
          fill="#8B8B8B"
        />
        <path
          d="M157.809 130.983C158.722 130.312 160.103 130.687 160.939 131.834L175.789 152.189C176.675 153.405 176.608 155.011 175.639 155.723L174.288 156.714C173.32 157.425 171.841 156.955 171.034 155.68L157.534 134.333C156.775 133.131 156.896 131.653 157.809 130.983Z"
          fill="#8B8B8B"
        />
      </svg>
    ),
  ghost: ({ dark = false }) =>
    !dark ? (
      <svg
        height="230"
        width="228"
        fill="none"
        viewBox="0 0 228 230"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M74.0622 223.806C89.98 233.4 101.336 229.417 105.683 221.723C114.091 206.845 142.045 176.419 186.344 166.136C245.015 152.516 232.762 116.353 196.695 97.376C160.627 78.3992 133.658 52.1413 137.74 23.5904C141.822 -4.96042 101.38 -14.8028 83.395 40.1194C71.7727 75.6122 50.9448 85.1209 37.6632 87.4066C25.1455 89.5607 11.4528 92.2636 4.77552 103.068C-3.32688 116.178 -0.49953 129.44 33.4234 138.536C81.4956 151.425 38.3833 202.299 74.0622 223.806Z"
          fill="#EEEEEE"
        />
        <path
          d="M32.7041 161.597C33.3778 161.212 40.981 157.993 78.8056 175.627C78.6954 175.777 78.6486 175.847 78.6486 175.847C78.6486 175.847 39.3262 189.09 31.6217 172.776C29.321 167.919 29.9803 164.575 32.6384 161.635L32.7041 161.597Z"
          fill="#929292"
        />
        <path
          d="M159.836 168.27C151.618 175.179 138.731 180.193 126.296 173.525C128.261 172.27 130.29 170.976 132.36 169.638C144.848 161.615 155.848 166.983 159.836 168.27Z"
          fill="#929292"
        />
        <path
          d="M174.555 83.3024C182.951 153.215 162.999 165.903 160.777 167.831C160.134 168.389 159.874 168.348 158.901 168.098L158.885 168.107C154.77 167.016 144.848 161.614 132.36 169.637C130.29 170.975 128.26 172.268 126.295 173.524C113.556 181.604 103.268 187.297 95.8567 183.993C89.57 180.786 83.9017 178.01 78.8057 175.626C40.9811 157.993 33.3779 161.211 32.7043 161.597C33.4859 161.171 40.6564 157.197 50.3113 150.207C50.3441 150.188 50.377 150.169 50.384 150.144C63.2891 140.791 80.5717 126.053 92.9944 107.094C101.451 94.2015 109.725 61.5183 125.687 55.1055C158.494 42.867 173.193 69.0818 174.555 83.3024Z"
          fill="#CECECE"
        />
        <path
          d="M138.28 82.0758C139.299 79.3868 139.199 76.8567 138.059 76.4246C136.918 75.9926 135.167 77.8223 134.149 80.5113C133.131 83.2003 133.23 85.7304 134.371 86.1625C135.512 86.5945 137.262 84.7648 138.28 82.0758Z"
          fill="white"
        />
        <path
          d="M159.169 85.5068C159.891 82.7234 159.518 80.2188 158.338 79.9126C157.157 79.6065 155.614 81.6146 154.893 84.398C154.171 87.1814 154.543 89.6859 155.724 89.9921C156.905 90.2983 158.447 88.2902 159.169 85.5068Z"
          fill="white"
        />
        <path
          d="M146.454 95.7264C146.283 95.7262 146.117 95.6675 145.984 95.5601C145.85 95.4526 145.758 95.3029 145.722 95.1357C145.588 94.5303 144.993 92.739 144.05 92.4727C143.107 92.2065 141.256 93.2401 140.508 93.7824C140.347 93.899 140.146 93.947 139.95 93.9155C139.753 93.8841 139.577 93.7759 139.461 93.6148C139.344 93.4537 139.296 93.2528 139.327 93.0564C139.359 92.86 139.467 92.6841 139.628 92.5674C139.923 92.3538 142.565 90.4951 144.457 91.029C146.43 91.5858 147.116 94.4875 147.187 94.8166C147.211 94.9262 147.21 95.0397 147.184 95.1488C147.159 95.2579 147.109 95.3599 147.038 95.4472C146.968 95.5345 146.879 95.6048 146.778 95.6532C146.676 95.7015 146.566 95.7265 146.454 95.7264H146.454Z"
          fill="white"
        />
      </svg>
    ) : (
      <svg
        height="230"
        width="228"
        fill="none"
        viewBox="0 0 228 230"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M74.0621 223.806C89.98 233.4 101.336 229.417 105.683 221.723C114.091 206.845 142.045 176.419 186.344 166.136C245.015 152.516 232.762 116.353 196.695 97.3761C160.627 78.3993 133.658 52.1413 137.74 23.5905C141.822 -4.96039 101.38 -14.8028 83.395 40.1194C71.7727 75.6123 50.9447 85.1209 37.6631 87.4066C25.1455 89.5608 11.4527 92.2636 4.77547 103.068C-3.32692 116.178 -0.499576 129.44 33.4234 138.536C81.4955 151.425 38.3833 202.299 74.0621 223.806Z"
          fill="#232323"
        />
        <path
          d="M32.7042 161.596C33.3778 161.21 40.981 157.991 78.8056 175.625C78.6954 175.775 78.6486 175.846 78.6486 175.846C78.6486 175.846 39.3262 189.089 31.6218 172.775C29.3211 167.918 29.9803 164.574 32.6385 161.634L32.7042 161.596Z"
          fill="#676767"
        />
        <path
          d="M159.836 168.27C151.618 175.179 138.731 180.193 126.296 173.525C128.261 172.269 130.29 170.976 132.36 169.638C144.848 161.614 155.848 166.983 159.836 168.27Z"
          fill="#676767"
        />
        <path
          d="M174.555 83.3017C182.951 153.214 162.999 165.903 160.777 167.83C160.134 168.388 159.874 168.347 158.901 168.097L158.885 168.107C154.77 167.016 144.849 161.614 132.36 169.637C130.29 170.974 128.26 172.268 126.295 173.523C113.556 181.603 103.268 187.297 95.8567 183.992C89.5701 180.785 83.9017 178.01 78.8058 175.626C40.9812 157.992 33.378 161.211 32.7043 161.596C33.486 161.171 40.6565 157.196 50.3113 150.206C50.3442 150.188 50.377 150.169 50.3841 150.143C63.2892 140.791 80.5717 126.052 92.9944 107.093C101.451 94.2008 109.725 61.5176 125.687 55.1048C158.494 42.8663 173.193 69.0811 174.555 83.3017Z"
          fill="#A5A5A5"
        />
        <path
          d="M138.28 82.0745C139.299 79.3855 139.199 76.8553 138.059 76.4233C136.918 75.9913 135.167 77.821 134.149 80.51C133.131 83.199 133.23 85.7291 134.371 86.1612C135.512 86.5932 137.262 84.7635 138.28 82.0745Z"
          fill="black"
        />
        <path
          d="M159.169 85.5065C159.891 82.7232 159.518 80.2186 158.338 79.9124C157.157 79.6062 155.614 81.6144 154.893 84.3978C154.171 87.1811 154.543 89.6857 155.724 89.9919C156.905 90.2981 158.447 88.2899 159.169 85.5065Z"
          fill="black"
        />
        <path
          d="M146.454 95.725C146.283 95.7248 146.117 95.6661 145.984 95.5587C145.85 95.4512 145.758 95.3015 145.722 95.1343C145.588 94.5289 144.993 92.7376 144.05 92.4713C143.107 92.2051 141.256 93.2387 140.508 93.781C140.347 93.8976 140.146 93.9455 139.949 93.9141C139.753 93.8827 139.577 93.7745 139.46 93.6134C139.344 93.4523 139.296 93.2514 139.327 93.055C139.359 92.8586 139.467 92.6826 139.628 92.566C139.923 92.3524 142.565 90.4937 144.457 91.0276C146.43 91.5844 147.116 94.4861 147.187 94.8152C147.211 94.9248 147.21 95.0383 147.184 95.1474C147.158 95.2565 147.109 95.3585 147.038 95.4458C146.968 95.5331 146.879 95.6034 146.777 95.6518C146.676 95.7001 146.566 95.7251 146.453 95.725H146.454Z"
          fill="black"
        />
      </svg>
    ),
  search: ({ dark = false }) =>
    !dark ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="190"
        height="191"
        fill="none"
        viewBox="0 0 190 191"
        className="small_margin"
      >
        <circle cx="87.775" cy="94.775" r="71.775" fill="#F1F1F1"></circle>
        <circle
          cx="87.188"
          cy="94.897"
          r="41.919"
          stroke="#C4C4C4"
          strokeWidth="2"
          style={{
            fill: "var(--bg-color)",
          }}
        ></circle>
        <circle
          r="29.657"
          cx="87.188"
          cy="94.897"
          stroke="#C4C4C4"
          strokeWidth="2"
          style={{
            fill: "var(--bg-color)",
          }}
        ></circle>
        <path
          fill="#C4C4C4"
          d="M87.188 72.359a.794.794 0 00-.816-.801A23.354 23.354 0 0063.954 92.54a.794.794 0 00.745.867.84.84 0 00.883-.759 21.723 21.723 0 0120.792-19.46.84.84 0 00.815-.83z"
        ></path>
        <rect
          width="14"
          height="42"
          x="119.864"
          y="140.411"
          style={{
            fill: "var(--bg-color)",
          }}
          stroke="#C4C4C4"
          strokeWidth="2"
          rx="7"
          transform="rotate(-45 119.864 140.411)"
        ></rect>
        <rect
          width="1.948"
          height="16.336"
          x="134.64"
          y="143.781"
          fill="#c4c4c4"
          rx="0.974"
          transform="rotate(-45 134.64 143.781)"
        ></rect>
        <path
          fill="#C4C4C4"
          d="M118.442 130.504H120.442V138.504H118.442z"
          transform="rotate(-45 118.442 130.504)"
        ></path>
        <rect
          width="20"
          height="2"
          x="139.663"
          y="89.861"
          fill="#C4C4C4"
          rx="1"
        ></rect>
        <rect
          width="20"
          height="2"
          x="16"
          y="106.406"
          fill="#C4C4C4"
          rx="1"
        ></rect>
        {/* <rect
          width="10"
          height="2"
          x="163.663"
          y="89.861"
          fill="#C4C4C4"
          rx="1"
        ></rect> */}
        <rect
          width="10"
          height="2"
          x="144.663"
          y="97.199"
          fill="#C4C4C4"
          rx="1"
        ></rect>
        <rect
          width="10"
          height="2"
          x="21"
          y="113.744"
          fill="#C4C4C4"
          rx="1"
        ></rect>
      </svg>
    ) : (
      <svg
        height="146"
        width="158"
        fill="none"
        viewBox="0 0 158 146"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="71.7747" cy="71.7747" fill="#232323" r="71.7747" />
        <circle
          cx="71.1878"
          cy="71.8973"
          r="41.9195"
          stroke="#9F9F9F"
          strokeWidth="2"
          style={{
            fill: "var(--bg-color)",
          }}
        />
        <circle
          cx="71.1878"
          cy="71.8973"
          r="29.6568"
          stroke="#9F9F9F"
          strokeWidth="2"
          style={{
            fill: "var(--bg-color)",
          }}
        />
        <path
          d="M71.1876 49.3588C71.1876 48.9087 70.8224 48.5422 70.3725 48.5579C64.7399 48.7545 59.3574 50.983 55.2292 54.8468C51.1009 58.7107 48.5215 63.9341 47.9531 69.5414C47.9077 69.9893 48.2492 70.3779 48.6984 70.4077V70.4077C49.1476 70.4374 49.5343 70.097 49.5809 69.6493C50.1212 64.4549 52.517 59.6182 56.3432 56.0371C60.1694 52.456 65.1538 50.3851 70.3726 50.1893C70.8224 50.1724 71.1876 49.809 71.1876 49.3588V49.3588Z"
          fill="#9F9F9F"
        />
        <rect
          height="42"
          width="14"
          rx="7"
          stroke="#9F9F9F"
          strokeWidth="2"
          transform="rotate(-45 103.864 117.411)"
          x="103.864"
          y="117.411"
          style={{
            fill: "var(--bg-color)",
          }}
        />
        <rect
          height="16.3364"
          width="1.94836"
          fill="#9f9f9f"
          rx="0.974179"
          transform="rotate(-45 118.64 120.781)"
          x="118.64"
          y="120.781"
        />
        <rect
          height="8"
          width="2"
          fill="#9F9F9F"
          transform="rotate(-45 102.442 107.504)"
          x="102.442"
          y="107.504"
        />
        <rect
          height="2"
          width="20"
          fill="#9F9F9F"
          rx="1"
          x="123.663"
          y="66.8611"
        />
        <rect height="2" width="20" fill="#9F9F9F" rx="1" y="83.4059" />
        {/* <rect
          height="2"
          width="10"
          fill="#9F9F9F"
          rx="1"
          x="147.663"
          y="66.8611"
        /> */}
        <rect
          height="2"
          width="10"
          fill="#9F9F9F"
          rx="1"
          x="128.663"
          y="74.1991"
        />
        <rect height="2" width="10" fill="#9F9F9F" rx="1" x="5" y="90.7439" />
      </svg>
    ),
  emptyMail: ({ dark = false }) =>
    !dark ? (
      <svg
        height="84"
        width="87"
        fill="none"
        viewBox="0 0 87 84"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M45.3886 8.25198C45.3886 8.25198 47.6359 22.7037 71.8607 23.1025C93.3746 23.4567 86.5371 49.0452 78.9004 57.0914C69.0079 67.5144 49.6204 64.7878 48.6029 76.9197C47.5853 89.0515 20.2536 84.3415 20.7191 66.0499C21.2983 43.2958 8.83463 50.2147 2.52495 44.1283C-2.00355 39.76 -0.446991 19.5392 13.9068 17.2242C25.9851 15.2762 25.3589 10.1588 26.3193 6.37042C27.7041 0.906793 41.7062 -4.04246 45.3886 8.25198Z"
          fill="#EEEEEE"
        />
        <path
          d="M68.1778 59.6947C68.1791 60.0619 68.1079 60.4257 67.9682 60.7653C67.8286 61.1048 67.6234 61.4136 67.3642 61.6737C67.1051 61.9338 66.7972 62.1402 66.4581 62.2811C66.119 62.422 65.7555 62.4946 65.3883 62.4947L20.6451 62.5856C20.278 62.5865 19.9144 62.5151 19.5749 62.3754C19.2354 62.2356 18.9269 62.0304 18.6668 61.7713C18.4068 61.5122 18.2003 61.2044 18.0593 60.8655C17.9183 60.5266 17.8455 60.1631 17.8451 59.7961L17.7822 28.3359L42.5483 12.7876C42.6591 12.7177 42.7874 12.6803 42.9184 12.6797C43.0494 12.6791 43.1779 12.7153 43.2894 12.7841L67.5346 27.8744L68.1149 28.2344V28.7378L68.1778 59.6947Z"
          fill="#7E7E7E"
        />
        <path
          d="M64.527 15.4951H21.1035C21 15.4951 20.916 15.5791 20.916 15.6826V62.1482C20.916 62.2518 21 62.3357 21.1035 62.3357H64.527C64.6306 62.3357 64.7145 62.2518 64.7145 62.1482V15.6826C64.7145 15.5791 64.6306 15.4951 64.527 15.4951Z"
          fill="#CECECE"
        />
        <path
          d="M65.3433 62.4507L43.135 45.5178C43.1172 45.5042 43.1028 45.4865 43.093 45.4662C43.0833 45.4459 43.0786 45.4236 43.0791 45.4011C43.0797 45.3786 43.0856 45.3566 43.0964 45.3369C43.1071 45.3171 43.1224 45.3002 43.141 45.2875L67.891 28.3714C67.9123 28.3569 67.9372 28.3484 67.963 28.3469C67.9888 28.3454 68.0145 28.351 68.0373 28.3631C68.0602 28.3751 68.0793 28.3931 68.0927 28.4152C68.1061 28.4373 68.1132 28.4627 68.1132 28.4885L68.1757 59.68C68.1772 60.4127 67.891 61.1167 67.3788 61.6406C66.8665 62.1645 66.1691 62.4664 65.4365 62.4814C65.4029 62.4819 65.37 62.4711 65.3433 62.4507V62.4507Z"
          fill="#C0C0C0"
        />
        <path
          d="M20.6828 62.5394L42.8231 45.5181C42.841 45.5044 42.8553 45.4867 42.8649 45.4663C42.8746 45.446 42.8792 45.4237 42.8786 45.4012C42.8779 45.3787 42.8719 45.3567 42.8611 45.337C42.8502 45.3173 42.8349 45.3004 42.8163 45.2878L17.9992 28.4707C17.9778 28.4562 17.9528 28.4479 17.9271 28.4465C17.9013 28.4451 17.8756 28.4508 17.8528 28.4629C17.83 28.475 17.8109 28.4931 17.7976 28.5153C17.7843 28.5374 17.7773 28.5628 17.7773 28.5886L17.8398 59.7801C17.8413 60.5128 18.1303 61.2156 18.6445 61.7374C19.1588 62.2593 19.8573 62.5585 20.5898 62.5706C20.6234 62.5709 20.6562 62.56 20.6828 62.5394Z"
          fill="#C0C0C0"
        />
        <path
          d="M65.3846 62.4815L20.6415 62.571C19.9646 62.5704 19.3108 62.325 18.8007 61.88C18.2907 61.435 17.9588 60.8205 17.8665 60.15C17.8513 60.0357 17.8645 59.9193 17.905 59.8113C17.9455 59.7033 18.012 59.607 18.0986 59.5309L42.5017 37.9412C42.6292 37.8284 42.7934 37.766 42.9635 37.7656C43.1337 37.7653 43.2982 37.827 43.4261 37.9393L67.9151 59.4312C68.002 59.507 68.0689 59.6031 68.1099 59.7109C68.1508 59.8188 68.1645 59.935 68.1497 60.0494C68.06 60.7203 67.7307 61.3361 67.2225 61.7831C66.7142 62.2301 66.0615 62.4782 65.3846 62.4815V62.4815Z"
          fill="#8B8B8B"
        />
        <path
          d="M32.031 27.0306C32.8998 27.0306 33.604 26.0916 33.604 24.9332C33.604 23.7749 32.8998 22.8359 32.031 22.8359C31.1623 22.8359 30.458 23.7749 30.458 24.9332C30.458 26.0916 31.1623 27.0306 32.031 27.0306Z"
          fill="white"
        />
        <path
          d="M54.0535 27.0306C54.9222 27.0306 55.6265 26.0916 55.6265 24.9332C55.6265 23.7749 54.9222 22.8359 54.0535 22.8359C53.1847 22.8359 52.4805 23.7749 52.4805 24.9332C52.4805 26.0916 53.1847 27.0306 54.0535 27.0306Z"
          fill="white"
        />
        <path
          d="M48.9847 33.9854C48.9317 33.9854 48.8795 33.972 48.833 33.9463C48.7866 33.9205 48.7475 33.8834 48.7194 33.8384C48.7029 33.8124 46.9922 31.1697 43.0161 30.8384C41.3611 30.7006 39.7906 31.1614 38.3499 32.2086C37.7073 32.67 37.1365 33.2239 36.6561 33.8524C36.6083 33.9196 36.5358 33.9653 36.4545 33.9793C36.3732 33.9934 36.2896 33.9746 36.222 33.9273C36.1544 33.8799 36.1083 33.8077 36.0938 33.7265C36.0792 33.6453 36.0974 33.5616 36.1443 33.4937C36.1705 33.4562 36.7992 32.5675 37.9637 31.7165C39.5118 30.5852 41.2769 30.0665 43.0679 30.2154C47.3692 30.5739 49.1746 33.3877 49.2491 33.5072C49.2787 33.5545 49.2951 33.6089 49.2966 33.6646C49.298 33.7203 49.2846 33.7755 49.2576 33.8243C49.2306 33.8731 49.191 33.9137 49.143 33.9421C49.0949 33.9704 49.0402 33.9854 48.9844 33.9854L48.9847 33.9854Z"
          fill="white"
        />
      </svg>
    ) : (
      <svg
        height="200"
        width="207"
        fill="none"
        viewBox="0 0 207 200"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M108.733 18.8055C108.733 18.8055 114.127 53.4896 172.267 54.4469C223.9 55.2969 207.49 116.709 189.162 136.02C165.42 161.035 118.89 154.491 116.448 183.608C114.006 212.724 48.4096 201.42 49.5268 157.521C50.9167 102.911 21.004 119.516 5.86075 104.909C-5.00765 94.4247 -1.2719 45.8948 33.1771 40.3388C62.1652 35.6636 60.6624 23.3819 62.9671 14.2898C66.2907 1.17708 99.8958 -10.7011 108.733 18.8055Z"
          fill="#232323"
        />
        <path
          d="M163.428 142.27C163.431 143.151 163.26 144.024 162.925 144.839C162.59 145.654 162.097 146.395 161.475 147.019C160.854 147.643 160.114 148.139 159.301 148.477C158.487 148.815 157.615 148.989 156.733 148.99L49.3495 149.208C48.4685 149.21 47.5957 149.039 46.781 148.703C45.9664 148.368 45.2258 147.875 44.6016 147.253C43.9775 146.632 43.4821 145.893 43.1437 145.079C42.8053 144.266 42.6306 143.394 42.6295 142.513L42.4786 67.0086L101.917 29.6926C102.183 29.5249 102.491 29.4351 102.805 29.4336C103.12 29.4321 103.428 29.519 103.696 29.6842L161.884 65.9008L163.277 66.765V67.9731L163.428 142.27Z"
          fill="#7E7E7E"
        />
        <path
          d="M154.667 36.1914H50.4502C50.2017 36.1914 50.0002 36.3929 50.0002 36.6414V148.159C50.0002 148.407 50.2017 148.609 50.4502 148.609H154.667C154.915 148.609 155.117 148.407 155.117 148.159V36.6414C155.117 36.3929 154.915 36.1914 154.667 36.1914Z"
          fill="#A5A5A5"
        />
        <path
          d="M156.626 148.883L103.326 108.244C103.283 108.211 103.249 108.169 103.225 108.12C103.202 108.071 103.191 108.018 103.192 107.964C103.193 107.91 103.208 107.857 103.233 107.81C103.259 107.762 103.296 107.722 103.34 107.691L162.74 67.0926C162.792 67.0576 162.851 67.0373 162.913 67.0338C162.975 67.0302 163.037 67.0436 163.092 67.0725C163.147 67.1014 163.192 67.1447 163.225 67.1977C163.257 67.2508 163.274 67.3115 163.274 67.3735L163.424 142.233C163.427 143.992 162.741 145.681 161.511 146.939C160.282 148.196 158.608 148.921 156.85 148.957C156.769 148.958 156.69 148.932 156.626 148.883Z"
          fill="#828181"
        />
        <path
          d="M49.4398 149.097L102.577 108.246C102.619 108.213 102.654 108.17 102.677 108.122C102.7 108.073 102.711 108.019 102.71 107.965C102.708 107.911 102.694 107.858 102.668 107.811C102.642 107.764 102.605 107.723 102.56 107.693L42.9991 67.3321C42.9477 67.2973 42.8879 67.2772 42.826 67.2739C42.7641 67.2706 42.7025 67.2842 42.6477 67.3133C42.593 67.3424 42.5472 67.3859 42.5153 67.439C42.4834 67.4922 42.4666 67.553 42.4667 67.615L42.6167 142.475C42.6202 144.233 43.3137 145.92 44.5479 147.172C45.7821 148.425 47.4586 149.143 49.2167 149.172C49.2973 149.173 49.3759 149.146 49.4398 149.097Z"
          fill="#828181"
        />
        <path
          d="M156.725 148.959L49.3414 149.174C47.7169 149.172 46.1477 148.583 44.9236 147.515C43.6994 146.447 42.903 144.972 42.6814 143.363C42.6448 143.089 42.6766 142.81 42.7738 142.55C42.8709 142.291 43.0306 142.06 43.2385 141.877L101.806 90.0619C102.112 89.7912 102.506 89.6415 102.914 89.6406C103.323 89.6398 103.717 89.788 104.024 90.0574L162.798 141.638C163.007 141.82 163.167 142.051 163.265 142.309C163.364 142.568 163.397 142.847 163.361 143.122C163.146 144.732 162.355 146.21 161.136 147.283C159.916 148.355 158.349 148.951 156.725 148.959Z"
          fill="#676767"
        />
        <path
          d="M76.6761 63.8757C78.7611 63.8757 80.4513 61.6221 80.4513 58.8421C80.4513 56.0622 78.7611 53.8086 76.6761 53.8086C74.5912 53.8086 72.9009 56.0622 72.9009 58.8421C72.9009 61.6221 74.5912 63.8757 76.6761 63.8757Z"
          fill="black"
        />
        <path
          d="M129.53 63.8757C131.615 63.8757 133.305 61.6221 133.305 58.8421C133.305 56.0622 131.615 53.8086 129.53 53.8086C127.445 53.8086 125.754 56.0622 125.754 58.8421C125.754 61.6221 127.445 63.8757 129.53 63.8757Z"
          fill="black"
        />
        <path
          d="M117.366 80.5668C117.238 80.567 117.113 80.5347 117.002 80.473C116.89 80.4113 116.796 80.3222 116.729 80.2142C116.689 80.1516 112.583 73.8092 103.041 73.0142C99.0689 72.6834 95.2996 73.7894 91.8419 76.3026C90.2997 77.4099 88.9299 78.7393 87.7769 80.2476C87.6622 80.4091 87.4882 80.5186 87.2931 80.5523C87.0979 80.586 86.8973 80.5411 86.7351 80.4274C86.5729 80.3138 86.4622 80.1405 86.4273 79.9456C86.3923 79.7506 86.4359 79.5497 86.5485 79.3868C86.6114 79.2968 88.1202 77.1639 90.9152 75.1215C94.6306 72.4065 98.8669 71.1615 103.165 71.519C113.488 72.3792 117.821 79.1324 118 79.4193C118.071 79.5328 118.11 79.6632 118.114 79.797C118.118 79.9308 118.085 80.0631 118.02 80.1802C117.956 80.2973 117.861 80.3949 117.745 80.4629C117.63 80.5309 117.499 80.5669 117.365 80.567L117.366 80.5668Z"
          fill="black"
        />
      </svg>
    ),
  wallets: ({ dark = false }) =>
    !dark ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="221"
        height="102"
        fill="none"
        viewBox="0 0 221 102"
        className="small_size large_margin"
      >
        <circle
          cx="9.924"
          cy="51.756"
          r="8.751"
          fill="#F1F1F1"
          stroke="#000"
          strokeWidth="0.646"
        ></circle>
        <rect
          width="160.317"
          height="18.149"
          x="4.63"
          y="6.383"
          fill="#F1F1F1"
          rx="7.755"
        ></rect>
        <rect
          width="160.317"
          height="18.149"
          x="59.834"
          y="42.682"
          fill="#F1F1F1"
          rx="7.755"
        ></rect>
        <rect
          width="110.407"
          height="18.149"
          x="29.585"
          y="79.736"
          fill="#F1F1F1"
          rx="7.755"
        ></rect>
        <rect
          width="96.369"
          height="63.096"
          x="5.792"
          y="23.13"
          fill="#F3F3F3"
          stroke="#999"
          strokeWidth="1.939"
          rx="4.2"
          transform="rotate(-12.973 5.792 23.13)"
        ></rect>
        <rect
          width="96.369"
          height="63.096"
          x="57.022"
          y="37.601"
          fill="#F3F3F3"
          stroke="#999"
          strokeWidth="1.939"
          rx="4.2"
        ></rect>
        <path fill="#DDD" d="M58.321 54.025H152.091V67.637H58.321z"></path>
        <path
          stroke="#999"
          strokeLinecap="round"
          strokeWidth="1.939"
          d="M58.321 54.025h35.542m3.403 0h8.697m3.403 0h34.785m7.563 13.612h-24.955m-8.697 0H74.958"
        ></path>
        <circle
          cx="174.022"
          cy="88.811"
          r="8.751"
          fill="#F1F1F1"
          stroke="#000"
          strokeWidth="0.646"
        ></circle>
        <circle cx="192.171" cy="15.458" r="9.075" fill="#F1F1F1"></circle>
      </svg>
    ) : (
      <svg
        height="102"
        width="220"
        fill="none"
        viewBox="0 0 220 102"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="9.07456"
          cy="51.4225"
          fill="#444444"
          r="8.75145"
          stroke="black"
          strokeWidth="0.646219"
        />
        <circle
          cx="173.173"
          cy="88.4769"
          fill="#444444"
          r="8.75145"
          stroke="black"
          strokeWidth="0.646219"
        />
        <circle cx="191.322" cy="15.1243" fill="#444444" r="9.07456" />
        <rect
          height="18.1491"
          width="160.317"
          fill="#444444"
          rx="7.75463"
          x="3.78101"
          y="6.04971"
        />
        <rect
          height="18.1491"
          width="160.317"
          fill="#444444"
          rx="7.75463"
          x="58.9846"
          y="42.3479"
        />
        <rect
          height="18.1491"
          width="110.407"
          fill="#444444"
          rx="7.75463"
          x="28.7361"
          y="79.4024"
        />
        <rect
          height="63.0957"
          width="96.369"
          fill="#888888"
          rx="4.20043"
          stroke="#999999"
          strokeWidth="1.93866"
          transform="rotate(-12.9733 4.9432 22.7967)"
          x="4.9432"
          y="22.7967"
        />
        <rect
          height="63.0957"
          width="96.369"
          fill="#888888"
          rx="4.20043"
          stroke="#AAAAAA"
          strokeWidth="1.93866"
          x="56.1728"
          y="37.2675"
        />
        <rect
          height="13.6118"
          width="93.7704"
          fill="#CBCBCB"
          x="57.4722"
          y="53.6911"
        />
        <path
          d="M57.4722 53.6911H93.0142M96.4171 53.6911H105.114M108.517 53.6911H143.302M150.864 67.3029H125.909M117.213 67.3029H74.1089"
          stroke="#AAAAAA"
          strokeLinecap="round"
          strokeWidth="1.93866"
        />
      </svg>
    ),
  portalbg: () => (
    <svg
      height="894"
      width="635"
      fill="none"
      viewBox="0 0 635 894"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <g clipPath="url(#clip0_357_3905)">
          <path
            d="M256.428 809.004L143.857 921.575C140.595 924.837 140.595 930.126 143.857 933.388L167.795 957.326C171.057 960.588 176.346 960.588 179.608 957.326L292.18 844.755C295.442 841.493 295.442 836.204 292.18 832.942L268.241 809.004C264.979 805.742 259.691 805.742 256.428 809.004Z"
            fill="white"
          />
          <path
            d="M345.062 897.637L232.49 1010.21C229.228 1013.47 229.228 1018.76 232.49 1022.02L256.429 1045.96C259.691 1049.22 264.98 1049.22 268.242 1045.96L380.813 933.389C384.075 930.127 384.075 924.838 380.813 921.576L356.875 897.637C353.613 894.375 348.324 894.375 345.062 897.637Z"
            fill="white"
          />
        </g>
        <g clipPath="url(#clip1_357_3905)">
          <path
            d="M400.527 664.905L289.519 775.913C286.302 779.13 286.339 784.382 289.601 787.644L313.539 811.582C316.801 814.844 322.053 814.881 325.27 811.664L436.278 700.656C439.494 697.439 439.458 692.187 436.196 688.925L412.257 664.987C408.995 661.725 403.743 661.688 400.527 664.905Z"
            fill="white"
          />
          <path
            d="M489.159 753.538L378.152 864.546C374.935 867.762 374.972 873.014 378.234 876.277L402.172 900.215C405.434 903.477 410.686 903.514 413.903 900.297L524.911 789.289C528.127 786.072 528.091 780.82 524.829 777.558L500.89 753.62C497.628 750.358 492.376 750.321 489.159 753.538Z"
            fill="white"
          />
          <path
            d="M373.962 779.358L315.836 837.484C312.619 840.701 312.656 845.953 315.918 849.215L339.856 873.153C343.118 876.415 348.37 876.452 351.587 873.235L409.713 815.109C412.929 811.893 412.893 806.641 409.631 803.379L385.692 779.44C382.43 776.178 377.178 776.142 373.962 779.358Z"
            fill="white"
          />
          <path
            d="M462.594 867.991L404.468 926.117C401.252 929.334 401.288 934.586 404.55 937.848L428.489 961.786C431.751 965.048 437.003 965.085 440.22 961.868L498.346 903.742C501.562 900.525 501.526 895.273 498.264 892.011L474.325 868.073C471.063 864.811 465.811 864.774 462.594 867.991Z"
            fill="white"
          />
        </g>
        <g clipPath="url(#clip2_357_3905)">
          <path
            d="M584.51 848.888L473.503 959.896C470.286 963.113 470.323 968.365 473.585 971.627L497.523 995.565C500.785 998.827 506.037 998.864 509.254 995.647L620.262 884.64C623.478 881.423 623.442 876.171 620.18 872.909L596.241 848.97C592.979 845.708 587.727 845.672 584.51 848.888Z"
            fill="white"
          />
        </g>
        <g clipPath="url(#clip3_357_3905)">
          <path
            d="M546.436 518.991L433.865 631.562C430.603 634.824 430.603 640.113 433.865 643.375L457.803 667.314C461.065 670.576 466.354 670.576 469.616 667.314L582.187 554.742C585.449 551.48 585.449 546.191 582.187 542.929L558.249 518.991C554.987 515.729 549.698 515.729 546.436 518.991Z"
            fill="white"
          />
          <path
            d="M635.07 607.625L522.498 720.196C519.236 723.458 519.236 728.747 522.498 732.009L546.437 755.947C549.699 759.209 554.987 759.209 558.249 755.947L670.821 643.376C674.083 640.114 674.083 634.825 670.821 631.563L646.882 607.625C643.62 604.363 638.332 604.363 635.07 607.625Z"
            fill="white"
          />
          <path
            d="M518.878 634.438L459.934 693.383C456.672 696.645 456.672 701.933 459.934 705.195L483.872 729.134C487.134 732.396 492.423 732.396 495.685 729.134L554.63 670.189C557.892 666.927 557.892 661.638 554.63 658.376L530.691 634.438C527.429 631.176 522.14 631.176 518.878 634.438Z"
            fill="white"
          />
          <path
            d="M607.511 723.071L548.567 782.015C545.305 785.277 545.305 790.566 548.567 793.828L572.505 817.767C575.767 821.029 581.056 821.029 584.318 817.767L643.262 758.822C646.524 755.56 646.524 750.271 643.262 747.009L619.324 723.071C616.062 719.809 610.773 719.809 607.511 723.071Z"
            fill="white"
          />
        </g>
        <g clipPath="url(#clip4_357_3905)">
          <path
            d="M730.421 702.975L617.85 815.547C614.588 818.809 614.588 824.098 617.85 827.36L641.788 851.298C645.05 854.56 650.339 854.56 653.601 851.298L766.172 738.726C769.434 735.464 769.434 730.176 766.172 726.914L742.234 702.975C738.972 699.713 733.683 699.713 730.421 702.975Z"
            fill="white"
          />
        </g>
        <g clipPath="url(#clip5_357_3905)">
          <path
            d="M690.539 374.892L579.531 485.9C576.314 489.117 576.351 494.369 579.613 497.631L603.551 521.569C606.813 524.831 612.065 524.868 615.282 521.651L726.29 410.643C729.507 407.427 729.47 402.175 726.208 398.913L702.27 374.974C699.008 371.712 693.756 371.676 690.539 374.892Z"
            fill="white"
          />
          <path
            d="M663.974 489.346L605.848 547.472C602.631 550.688 602.668 555.94 605.93 559.202L629.868 583.141C633.13 586.403 638.382 586.439 641.599 583.223L699.725 525.097C702.942 521.88 702.905 516.628 699.643 513.366L675.705 489.428C672.443 486.166 667.191 486.129 663.974 489.346Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_357_3905">
          <rect
            height="331.798"
            width="331.798"
            fill="white"
            transform="translate(40.3799 958.02) rotate(-45)"
          />
        </clipPath>
        <clipPath id="clip1_357_3905">
          <rect
            height="331.798"
            width="327.19"
            fill="white"
            transform="translate(187.013 811.385) rotate(-45)"
          />
        </clipPath>
        <clipPath id="clip2_357_3905">
          <rect
            height="331.798"
            width="327.19"
            fill="white"
            transform="translate(370.997 995.368) rotate(-45)"
          />
        </clipPath>
        <clipPath id="clip3_357_3905">
          <rect
            height="331.798"
            width="331.798"
            fill="white"
            transform="translate(330.388 668.007) rotate(-45)"
          />
        </clipPath>
        <clipPath id="clip4_357_3905">
          <rect
            height="331.798"
            width="331.798"
            fill="white"
            transform="translate(514.373 851.991) rotate(-45)"
          />
        </clipPath>
        <clipPath id="clip5_357_3905">
          <rect
            height="331.798"
            width="327.19"
            fill="white"
            transform="translate(477.025 521.372) rotate(-45)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
  logo: ({ dark = false }) =>
    !dark ? (
      <svg
        height="28"
        width="130"
        fill="none"
        viewBox="0 0 130 28"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_390_6134)">
          <path
            d="M22.5728 0H3.83445C3.29162 0 2.85156 0.437826 2.85156 0.977912V4.94285C2.85156 5.48294 3.29162 5.92076 3.83445 5.92076H22.5728C23.1156 5.92076 23.5557 5.48294 23.5557 4.94285V0.977912C23.5557 0.437826 23.1156 0 22.5728 0Z"
            fill="#006174"
          />
          <path
            d="M22.5728 14.6787H3.83445C3.29162 14.6787 2.85156 15.1165 2.85156 15.6566V19.6216C2.85156 20.1617 3.29162 20.5995 3.83445 20.5995H22.5728C23.1156 20.5995 23.5557 20.1617 23.5557 19.6216V15.6566C23.5557 15.1165 23.1156 14.6787 22.5728 14.6787Z"
            fill="#006174"
          />
          <path
            d="M10.7955 7.40039H0.982885C0.440053 7.40039 0 7.83822 0 8.3783V12.3432C0 12.8833 0.440053 13.3212 0.982885 13.3212H10.7955C11.3384 13.3212 11.7784 12.8833 11.7784 12.3432V8.3783C11.7784 7.83822 11.3384 7.40039 10.7955 7.40039Z"
            fill="#006174"
          />
          <path
            d="M10.7955 22.0791H0.982885C0.440053 22.0791 0 22.5169 0 23.057V27.022C0 27.562 0.440053 27.9999 0.982885 27.9999H10.7955C11.3384 27.9999 11.7784 27.562 11.7784 27.022V23.057C11.7784 22.5169 11.3384 22.0791 10.7955 22.0791Z"
            fill="#006174"
          />
          <path
            d="M30.2502 5.18066H41.5329V8.63372H33.97V12.088H41.0358V15.5423H33.97V22.0791H30.2502V5.18066Z"
            fill="black"
          />
          <path
            d="M43.8872 4.07031H47.4836V21.9565H43.8872V4.07031Z"
            fill="black"
          />
          <path
            d="M57.153 20.5995H57.1044C56.3046 21.8674 54.8509 22.3756 53.3672 22.3756C51.1635 22.3756 49.1753 21.1064 49.1753 18.7255C49.1753 14.6354 54.1196 14.5622 57.148 14.5622C57.148 13.272 56.0817 12.4453 54.821 12.4453C53.6338 12.4453 52.6409 12.9807 51.8648 13.857L49.9352 11.8615C51.2694 10.5936 53.2077 9.95898 55.1212 9.95898C59.3567 9.95898 60.5003 12.1243 60.5003 16.0681V22.0794H57.153V20.5995ZM56.3968 17.0225C55.3018 17.0225 52.8764 17.1167 52.8764 18.6784C52.8764 19.5311 53.7795 19.9339 54.5643 19.9339C55.9683 19.9339 57.2776 19.1989 57.2776 17.7798V17.0225H56.3968Z"
            fill="black"
          />
          <path
            d="M69.3065 13.8968C69.0615 13.5857 68.7468 13.3358 68.3876 13.1671C68.0283 12.9984 67.6344 12.9155 67.2373 12.9251C66.6418 12.9251 65.9293 13.1853 65.9293 13.8732C65.9293 15.5762 71.9487 14.1335 71.9487 18.5124C71.9487 21.4462 69.0972 22.2989 66.6169 22.2989C65.0049 22.2989 63.2148 21.8973 62.0737 20.7372L64.2376 18.3439C64.904 19.1483 65.6901 19.5982 66.7602 19.5982C67.5687 19.5982 68.3796 19.385 68.3796 18.7702C68.3796 16.9198 62.3615 18.4864 62.3615 14.1075C62.3615 11.408 64.7869 10.2256 67.2199 10.2256C68.7422 10.2256 70.4301 10.5974 71.5015 11.7402L69.3065 13.8968Z"
            fill="black"
          />
          <path
            d="M77.3601 4.07031V12.0882H77.4074C77.7413 11.1847 78.9023 10.229 80.6463 10.229C84.2589 10.229 84.9267 12.6088 84.9267 15.7024V22.0805H81.329V16.3791C81.3439 15.0529 81.3202 13.3499 79.484 13.3499C77.6254 13.3499 77.3439 14.7926 77.3663 16.2837V21.9541H73.7661V4.07031H77.3601Z"
            fill="black"
          />
          <path
            d="M87.8992 5.30371H94.1677C97.6695 5.30371 100.575 6.4192 100.575 10.4003C100.575 14.333 97.8837 15.5427 94.3346 15.5427H91.6189V22.0794H87.8992V5.30371ZM91.6189 12.335H94.0805C95.3786 12.335 96.6779 11.8888 96.6779 10.3383C96.6779 8.62415 94.9338 8.38742 93.5125 8.38742H91.6189V12.335Z"
            fill="black"
          />
          <path
            d="M109.719 20.5995H109.672C108.901 21.8674 107.497 22.3756 106.069 22.3756C103.94 22.3756 102.023 21.1064 102.023 18.7255C102.023 14.6354 106.794 14.5622 109.719 14.5622C109.719 13.272 108.69 12.4453 107.477 12.4453C106.33 12.4453 105.37 12.9807 104.622 13.857L102.742 11.8615C104.03 10.5936 105.901 9.95898 107.75 9.95898C111.843 9.95898 112.943 12.1243 112.943 16.0681V22.0794H109.719V20.5995ZM108.84 17.0225C107.745 17.0225 105.318 17.1167 105.318 18.6784C105.318 19.5311 106.223 19.9339 107.007 19.9339C108.41 19.9339 109.719 19.1989 109.719 17.7798V17.0225H108.84Z"
            fill="black"
          />
          <path
            d="M121.869 23.9409C120.91 26.3962 120.359 27.7831 116.936 27.7831C116.024 27.7843 115.118 27.6395 114.253 27.3543L114.732 24.4168C115.259 24.6532 115.831 24.7753 116.409 24.775C118.028 24.775 118.3 24.0834 118.802 22.8204L119.067 22.1536L113.966 10.4844H117.847L120.836 18.1329H120.884L123.452 10.4844H127.14L121.869 23.9409Z"
            fill="black"
          />
          <path
            d="M128.637 10.5117C128.367 10.5115 128.103 10.5909 127.879 10.7399C127.654 10.889 127.479 11.1009 127.376 11.349C127.273 11.597 127.246 11.87 127.298 12.1334C127.351 12.3967 127.481 12.6387 127.672 12.8286C127.862 13.0184 128.106 13.1477 128.37 13.2001C128.635 13.2524 128.909 13.2254 129.159 13.1225C129.408 13.0197 129.621 12.8455 129.771 12.6221C129.921 12.3988 130 12.1362 130 11.8677C130 11.508 129.857 11.1632 129.601 10.9089C129.345 10.6546 128.999 10.5117 128.637 10.5117V10.5117ZM128.637 12.9522C128.483 12.9522 128.331 12.9216 128.19 12.862C128.048 12.8024 127.92 12.7151 127.813 12.6053C127.705 12.4955 127.622 12.3654 127.566 12.2227C127.51 12.08 127.484 11.9277 127.489 11.7747C127.499 11.483 127.619 11.2059 127.826 10.9989C128.033 10.792 128.311 10.6706 128.604 10.6592C128.757 10.6524 128.91 10.6763 129.054 10.7294C129.199 10.7826 129.33 10.864 129.442 10.9687C129.554 11.0735 129.644 11.1995 129.706 11.3394C129.768 11.4792 129.801 11.63 129.803 11.7828C129.806 11.9357 129.777 12.0874 129.72 12.2292C129.662 12.3709 129.577 12.4996 129.468 12.6079C129.36 12.7161 129.23 12.8015 129.088 12.8592C128.946 12.9168 128.793 12.9455 128.64 12.9435L128.637 12.9522Z"
            fill="black"
          />
          <path
            d="M128.022 11.8695V11.6964H129.23V11.8695H128.723V13.3076H128.528V11.8695H128.022ZM129.531 11.6964H129.764L130.311 13.0338H130.33L130.878 11.6964H131.111V13.3076H130.928V12.0835H130.913L130.409 13.3076H130.233L129.729 12.0835H129.714V13.3076H129.531V11.6964Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_390_6134">
            <rect height="28" width="130" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ) : (
      <svg
        height="28"
        width="130"
        fill="none"
        viewBox="0 0 130 28"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_817_10377)">
          <path
            d="M22.5728 0H3.83445C3.29162 0 2.85156 0.437826 2.85156 0.977912V4.94285C2.85156 5.48294 3.29162 5.92076 3.83445 5.92076H22.5728C23.1156 5.92076 23.5557 5.48294 23.5557 4.94285V0.977912C23.5557 0.437826 23.1156 0 22.5728 0Z"
            fill="white"
          />
          <path
            d="M22.5728 14.6777H3.83445C3.29162 14.6777 2.85156 15.1155 2.85156 15.6556V19.6206C2.85156 20.1607 3.29162 20.5985 3.83445 20.5985H22.5728C23.1156 20.5985 23.5557 20.1607 23.5557 19.6206V15.6556C23.5557 15.1155 23.1156 14.6777 22.5728 14.6777Z"
            fill="white"
          />
          <path
            d="M10.7955 7.40039H0.982885C0.440053 7.40039 0 7.83822 0 8.3783V12.3432C0 12.8833 0.440053 13.3212 0.982885 13.3212H10.7955C11.3384 13.3212 11.7784 12.8833 11.7784 12.3432V8.3783C11.7784 7.83822 11.3384 7.40039 10.7955 7.40039Z"
            fill="white"
          />
          <path
            d="M10.7955 22.0781H0.982885C0.440053 22.0781 0 22.5159 0 23.056V27.021C0 27.561 0.440053 27.9989 0.982885 27.9989H10.7955C11.3384 27.9989 11.7784 27.561 11.7784 27.021V23.056C11.7784 22.5159 11.3384 22.0781 10.7955 22.0781Z"
            fill="white"
          />
          <path
            d="M30.2502 5.17969H41.5329V8.63275H33.97V12.087H41.0358V15.5413H33.97V22.0781H30.2502V5.17969Z"
            fill="white"
          />
          <path
            d="M43.8872 4.07031H47.4836V21.9565H43.8872V4.07031Z"
            fill="white"
          />
          <path
            d="M57.153 20.5995H57.1044C56.3046 21.8674 54.8509 22.3756 53.3672 22.3756C51.1635 22.3756 49.1753 21.1064 49.1753 18.7255C49.1753 14.6354 54.1196 14.5622 57.148 14.5622C57.148 13.272 56.0817 12.4453 54.821 12.4453C53.6338 12.4453 52.6409 12.9807 51.8648 13.857L49.9352 11.8615C51.2694 10.5936 53.2077 9.95898 55.1212 9.95898C59.3567 9.95898 60.5003 12.1243 60.5003 16.0681V22.0794H57.153V20.5995ZM56.3968 17.0225C55.3018 17.0225 52.8764 17.1167 52.8764 18.6784C52.8764 19.5311 53.7795 19.9339 54.5643 19.9339C55.9683 19.9339 57.2776 19.1989 57.2776 17.7798V17.0225H56.3968Z"
            fill="white"
          />
          <path
            d="M69.3065 13.8978C69.0615 13.5867 68.7468 13.3368 68.3876 13.1681C68.0283 12.9994 67.6344 12.9165 67.2373 12.9261C66.6418 12.9261 65.9293 13.1863 65.9293 13.8742C65.9293 15.5772 71.9487 14.1345 71.9487 18.5134C71.9487 21.4472 69.0972 22.2999 66.6169 22.2999C65.0049 22.2999 63.2148 21.8983 62.0737 20.7382L64.2376 18.3449C64.904 19.1493 65.6901 19.5992 66.7602 19.5992C67.5687 19.5992 68.3796 19.386 68.3796 18.7712C68.3796 16.9208 62.3615 18.4874 62.3615 14.1085C62.3615 11.409 64.7869 10.2266 67.2199 10.2266C68.7422 10.2266 70.4301 10.5984 71.5015 11.7412L69.3065 13.8978Z"
            fill="white"
          />
          <path
            d="M77.3601 4.07031V12.0882H77.4074C77.7413 11.1847 78.9023 10.229 80.6463 10.229C84.2589 10.229 84.9267 12.6088 84.9267 15.7024V22.0805H81.329V16.3791C81.3439 15.0529 81.3202 13.3499 79.484 13.3499C77.6254 13.3499 77.3439 14.7926 77.3663 16.2837V21.9541H73.7661V4.07031H77.3601Z"
            fill="white"
          />
          <path
            d="M87.8992 5.30273H94.1677C97.6695 5.30273 100.575 6.41822 100.575 10.3993C100.575 14.332 97.8837 15.5417 94.3346 15.5417H91.6189V22.0784H87.8992V5.30273ZM91.6189 12.334H94.0805C95.3786 12.334 96.6779 11.8878 96.6779 10.3373C96.6779 8.62317 94.9338 8.38644 93.5125 8.38644H91.6189V12.334Z"
            fill="white"
          />
          <path
            d="M109.719 20.5995H109.672C108.901 21.8674 107.497 22.3756 106.069 22.3756C103.94 22.3756 102.023 21.1064 102.023 18.7255C102.023 14.6354 106.794 14.5622 109.719 14.5622C109.719 13.272 108.69 12.4453 107.477 12.4453C106.33 12.4453 105.37 12.9807 104.622 13.857L102.742 11.8615C104.03 10.5936 105.901 9.95898 107.75 9.95898C111.843 9.95898 112.943 12.1243 112.943 16.0681V22.0794H109.719V20.5995ZM108.84 17.0225C107.745 17.0225 105.318 17.1167 105.318 18.6784C105.318 19.5311 106.223 19.9339 107.007 19.9339C108.41 19.9339 109.719 19.1989 109.719 17.7798V17.0225H108.84Z"
            fill="white"
          />
          <path
            d="M121.869 23.9409C120.91 26.3962 120.359 27.7831 116.936 27.7831C116.024 27.7843 115.118 27.6395 114.253 27.3543L114.732 24.4168C115.259 24.6532 115.831 24.7753 116.409 24.775C118.028 24.775 118.3 24.0834 118.802 22.8204L119.067 22.1536L113.966 10.4844H117.847L120.836 18.1329H120.884L123.452 10.4844H127.14L121.869 23.9409Z"
            fill="white"
          />
          <path
            d="M128.637 10.5117C128.367 10.5115 128.103 10.5909 127.879 10.7399C127.654 10.889 127.479 11.1009 127.376 11.349C127.273 11.597 127.246 11.87 127.298 12.1334C127.351 12.3967 127.481 12.6387 127.672 12.8286C127.862 13.0184 128.106 13.1477 128.37 13.2001C128.635 13.2524 128.909 13.2254 129.159 13.1225C129.408 13.0197 129.621 12.8455 129.771 12.6221C129.921 12.3988 130 12.1362 130 11.8677C130 11.508 129.857 11.1632 129.601 10.9089C129.345 10.6546 128.999 10.5117 128.637 10.5117ZM128.637 12.9522C128.483 12.9522 128.331 12.9216 128.19 12.862C128.048 12.8024 127.92 12.7151 127.813 12.6053C127.705 12.4955 127.622 12.3654 127.566 12.2227C127.51 12.08 127.484 11.9277 127.489 11.7747C127.499 11.483 127.619 11.2059 127.826 10.9989C128.033 10.792 128.311 10.6706 128.604 10.6592C128.757 10.6524 128.91 10.6763 129.054 10.7294C129.199 10.7826 129.33 10.864 129.442 10.9687C129.554 11.0735 129.644 11.1995 129.706 11.3394C129.768 11.4792 129.801 11.63 129.803 11.7828C129.806 11.9357 129.777 12.0874 129.72 12.2292C129.662 12.3709 129.577 12.4996 129.468 12.6079C129.36 12.7161 129.23 12.8015 129.088 12.8592C128.946 12.9168 128.793 12.9455 128.64 12.9435L128.637 12.9522Z"
            fill="white"
          />
          <path
            d="M128.022 11.8704V11.6973H129.23V11.8704H128.723V13.3085H128.528V11.8704H128.022ZM129.531 11.6973H129.764L130.311 13.0347H130.33L130.878 11.6973H131.111V13.3085H130.928V12.0844H130.913L130.409 13.3085H130.233L129.729 12.0844H129.714V13.3085H129.531V11.6973Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_817_10377">
            <rect height="28" width="130" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
};

export default Vectors;
