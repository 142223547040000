const AppIcons = ({ type }) => {
  return type === "flashpay-main" ? (
    <svg
      height="28"
      width="130"
      fill="none"
      viewBox="0 0 130 28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_390_6134)">
        <path
          d="M22.5728 0H3.83445C3.29162 0 2.85156 0.437826 2.85156 0.977912V4.94285C2.85156 5.48294 3.29162 5.92076 3.83445 5.92076H22.5728C23.1156 5.92076 23.5557 5.48294 23.5557 4.94285V0.977912C23.5557 0.437826 23.1156 0 22.5728 0Z"
          fill="#006174"
        />
        <path
          d="M22.5728 14.6787H3.83445C3.29162 14.6787 2.85156 15.1165 2.85156 15.6566V19.6216C2.85156 20.1617 3.29162 20.5995 3.83445 20.5995H22.5728C23.1156 20.5995 23.5557 20.1617 23.5557 19.6216V15.6566C23.5557 15.1165 23.1156 14.6787 22.5728 14.6787Z"
          fill="#006174"
        />
        <path
          d="M10.7955 7.40039H0.982885C0.440053 7.40039 0 7.83822 0 8.3783V12.3432C0 12.8833 0.440053 13.3212 0.982885 13.3212H10.7955C11.3384 13.3212 11.7784 12.8833 11.7784 12.3432V8.3783C11.7784 7.83822 11.3384 7.40039 10.7955 7.40039Z"
          fill="#006174"
        />
        <path
          d="M10.7955 22.0791H0.982885C0.440053 22.0791 0 22.5169 0 23.057V27.022C0 27.562 0.440053 27.9999 0.982885 27.9999H10.7955C11.3384 27.9999 11.7784 27.562 11.7784 27.022V23.057C11.7784 22.5169 11.3384 22.0791 10.7955 22.0791Z"
          fill="#006174"
        />
        <path
          d="M30.2502 5.18066H41.5329V8.63372H33.97V12.088H41.0358V15.5423H33.97V22.0791H30.2502V5.18066Z"
          fill="black"
        />
        <path
          d="M43.8872 4.07031H47.4836V21.9565H43.8872V4.07031Z"
          fill="black"
        />
        <path
          d="M57.153 20.5995H57.1044C56.3046 21.8674 54.8509 22.3756 53.3672 22.3756C51.1635 22.3756 49.1753 21.1064 49.1753 18.7255C49.1753 14.6354 54.1196 14.5622 57.148 14.5622C57.148 13.272 56.0817 12.4453 54.821 12.4453C53.6338 12.4453 52.6409 12.9807 51.8648 13.857L49.9352 11.8615C51.2694 10.5936 53.2077 9.95898 55.1212 9.95898C59.3567 9.95898 60.5003 12.1243 60.5003 16.0681V22.0794H57.153V20.5995ZM56.3968 17.0225C55.3018 17.0225 52.8764 17.1167 52.8764 18.6784C52.8764 19.5311 53.7795 19.9339 54.5643 19.9339C55.9683 19.9339 57.2776 19.1989 57.2776 17.7798V17.0225H56.3968Z"
          fill="black"
        />
        <path
          d="M69.3065 13.8968C69.0615 13.5857 68.7468 13.3358 68.3876 13.1671C68.0283 12.9984 67.6344 12.9155 67.2373 12.9251C66.6418 12.9251 65.9293 13.1853 65.9293 13.8732C65.9293 15.5762 71.9487 14.1335 71.9487 18.5124C71.9487 21.4462 69.0972 22.2989 66.6169 22.2989C65.0049 22.2989 63.2148 21.8973 62.0737 20.7372L64.2376 18.3439C64.904 19.1483 65.6901 19.5982 66.7602 19.5982C67.5687 19.5982 68.3796 19.385 68.3796 18.7702C68.3796 16.9198 62.3615 18.4864 62.3615 14.1075C62.3615 11.408 64.7869 10.2256 67.2199 10.2256C68.7422 10.2256 70.4301 10.5974 71.5015 11.7402L69.3065 13.8968Z"
          fill="black"
        />
        <path
          d="M77.3601 4.07031V12.0882H77.4074C77.7413 11.1847 78.9023 10.229 80.6463 10.229C84.2589 10.229 84.9267 12.6088 84.9267 15.7024V22.0805H81.329V16.3791C81.3439 15.0529 81.3202 13.3499 79.484 13.3499C77.6254 13.3499 77.3439 14.7926 77.3663 16.2837V21.9541H73.7661V4.07031H77.3601Z"
          fill="black"
        />
        <path
          d="M87.8992 5.30371H94.1677C97.6695 5.30371 100.575 6.4192 100.575 10.4003C100.575 14.333 97.8837 15.5427 94.3346 15.5427H91.6189V22.0794H87.8992V5.30371ZM91.6189 12.335H94.0805C95.3786 12.335 96.6779 11.8888 96.6779 10.3383C96.6779 8.62415 94.9338 8.38742 93.5125 8.38742H91.6189V12.335Z"
          fill="black"
        />
        <path
          d="M109.719 20.5995H109.672C108.901 21.8674 107.497 22.3756 106.069 22.3756C103.94 22.3756 102.023 21.1064 102.023 18.7255C102.023 14.6354 106.794 14.5622 109.719 14.5622C109.719 13.272 108.69 12.4453 107.477 12.4453C106.33 12.4453 105.37 12.9807 104.622 13.857L102.742 11.8615C104.03 10.5936 105.901 9.95898 107.75 9.95898C111.843 9.95898 112.943 12.1243 112.943 16.0681V22.0794H109.719V20.5995ZM108.84 17.0225C107.745 17.0225 105.318 17.1167 105.318 18.6784C105.318 19.5311 106.223 19.9339 107.007 19.9339C108.41 19.9339 109.719 19.1989 109.719 17.7798V17.0225H108.84Z"
          fill="black"
        />
        <path
          d="M121.869 23.9409C120.91 26.3962 120.359 27.7831 116.936 27.7831C116.024 27.7843 115.118 27.6395 114.253 27.3543L114.732 24.4168C115.259 24.6532 115.831 24.7753 116.409 24.775C118.028 24.775 118.3 24.0834 118.802 22.8204L119.067 22.1536L113.966 10.4844H117.847L120.836 18.1329H120.884L123.452 10.4844H127.14L121.869 23.9409Z"
          fill="black"
        />
        <path
          d="M128.637 10.5117C128.367 10.5115 128.103 10.5909 127.879 10.7399C127.654 10.889 127.479 11.1009 127.376 11.349C127.273 11.597 127.246 11.87 127.298 12.1334C127.351 12.3967 127.481 12.6387 127.672 12.8286C127.862 13.0184 128.106 13.1477 128.37 13.2001C128.635 13.2524 128.909 13.2254 129.159 13.1225C129.408 13.0197 129.621 12.8455 129.771 12.6221C129.921 12.3988 130 12.1362 130 11.8677C130 11.508 129.857 11.1632 129.601 10.9089C129.345 10.6546 128.999 10.5117 128.637 10.5117V10.5117ZM128.637 12.9522C128.483 12.9522 128.331 12.9216 128.19 12.862C128.048 12.8024 127.92 12.7151 127.813 12.6053C127.705 12.4955 127.622 12.3654 127.566 12.2227C127.51 12.08 127.484 11.9277 127.489 11.7747C127.499 11.483 127.619 11.2059 127.826 10.9989C128.033 10.792 128.311 10.6706 128.604 10.6592C128.757 10.6524 128.91 10.6763 129.054 10.7294C129.199 10.7826 129.33 10.864 129.442 10.9687C129.554 11.0735 129.644 11.1995 129.706 11.3394C129.768 11.4792 129.801 11.63 129.803 11.7828C129.806 11.9357 129.777 12.0874 129.72 12.2292C129.662 12.3709 129.577 12.4996 129.468 12.6079C129.36 12.7161 129.23 12.8015 129.088 12.8592C128.946 12.9168 128.793 12.9455 128.64 12.9435L128.637 12.9522Z"
          fill="black"
        />
        <path
          d="M128.022 11.8695V11.6964H129.23V11.8695H128.723V13.3076H128.528V11.8695H128.022ZM129.531 11.6964H129.764L130.311 13.0338H130.33L130.878 11.6964H131.111V13.3076H130.928V12.0835H130.913L130.409 13.3076H130.233L129.729 12.0835H129.714V13.3076H129.531V11.6964Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_390_6134">
          <rect height="28" width="130" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ) : type === "copy-link" ? (
    <svg
      height="14"
      width="22"
      fill="none"
      viewBox="0 0 22 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9902 12.5H15.5002C18.5202 12.5 21.0002 10.03 21.0002 7C21.0002 3.98 18.5302 1.5 15.5002 1.5H13.9902"
        stroke="#006174"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <path
        d="M8 1.5H6.5C3.47 1.5 1 3.97 1 7C1 10.02 3.47 12.5 6.5 12.5H8"
        stroke="#006174"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <path
        d="M7 7H15"
        stroke="#006174"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
    </svg>
  ) : type === "flashpay-white" ? (
    <svg
      height="42"
      width="195"
      fill="none"
      viewBox="0 0 195 42"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M190.59 17.3072V17.1509H191.681V17.3072H191.224V18.6055H191.048V17.3072H190.59ZM191.953 17.1509H192.163L192.658 18.3583H192.675L193.169 17.1509H193.379V18.6055H193.214V17.5004H193.2L192.746 18.6055H192.587L192.132 17.5004H192.118V18.6055H191.953V17.1509Z"
        fill="white"
      />
      <path
        d="M191.966 15.7676C191.563 15.7672 191.17 15.8863 190.835 16.1099C190.5 16.3334 190.238 16.6514 190.084 17.0234C189.93 17.3955 189.889 17.805 189.968 18.2C190.046 18.5951 190.24 18.958 190.525 19.2428C190.81 19.5277 191.173 19.7216 191.568 19.8001C191.963 19.8786 192.372 19.8381 192.744 19.6838C193.116 19.5295 193.434 19.2683 193.658 18.9332C193.881 18.5981 194 18.2043 194 17.8015C194 17.2621 193.786 16.7447 193.404 16.3633C193.023 15.9819 192.506 15.7676 191.966 15.7676V15.7676ZM191.966 19.4282C191.737 19.4283 191.509 19.3823 191.298 19.293C191.087 19.2036 190.895 19.0726 190.735 18.9079C190.576 18.7432 190.45 18.548 190.367 18.334C190.284 18.12 190.245 17.8915 190.252 17.6621C190.267 17.2245 190.447 16.8088 190.755 16.4984C191.064 16.188 191.479 16.0059 191.916 15.9888C192.145 15.9786 192.374 16.0144 192.589 16.0941C192.804 16.1739 193 16.296 193.167 16.4531C193.334 16.6103 193.468 16.7993 193.561 17.0091C193.653 17.2188 193.703 17.445 193.706 17.6743C193.71 17.9035 193.668 18.1312 193.582 18.3437C193.496 18.5563 193.368 18.7495 193.206 18.9118C193.044 19.0741 192.851 19.2023 192.639 19.2888C192.427 19.3753 192.199 19.4183 191.97 19.4152L191.966 19.4282Z"
        fill="white"
      />
      <path
        d="M181.866 35.9113C180.435 39.5943 179.613 41.6747 174.505 41.6747C173.144 41.6764 171.792 41.4592 170.5 41.0314L171.216 36.6252C172.003 36.9798 172.856 37.163 173.718 37.1625C176.135 37.1625 176.54 36.1251 177.289 34.2307L177.685 33.2304L170.073 15.7266H175.864L180.325 27.1994H180.396L184.229 15.7266H189.732L181.866 35.9113Z"
        fill="white"
      />
      <path
        d="M163.735 30.8993H163.664C162.514 32.8012 160.419 33.5634 158.288 33.5634C155.111 33.5634 152.25 31.6597 152.25 28.0882C152.25 21.953 159.37 21.8434 163.735 21.8434C163.735 19.908 162.199 18.6679 160.389 18.6679C158.677 18.6679 157.245 19.4711 156.128 20.7855L153.323 17.7923C155.245 15.8904 158.037 14.9385 160.796 14.9385C166.905 14.9385 168.546 18.1864 168.546 24.1022V33.1191H163.735V30.8993ZM162.423 25.5338C160.788 25.5338 157.167 25.6751 157.167 28.0176C157.167 29.2967 158.517 29.9009 159.688 29.9009C161.781 29.9009 163.735 28.7984 163.735 26.6697V25.5338H162.423Z"
        fill="white"
      />
      <path
        d="M131.172 7.95508H140.527C145.753 7.95508 150.088 9.62831 150.088 15.5999C150.088 21.499 146.072 23.3135 140.776 23.3135H136.723V33.1186H131.172V7.95508ZM136.723 18.502H140.397C142.334 18.502 144.273 17.8327 144.273 15.5069C144.273 12.9357 141.67 12.5806 139.549 12.5806H136.723V18.502Z"
        fill="white"
      />
      <path
        d="M115.445 6.10547V18.1323H115.516C116.014 16.777 117.747 15.3436 120.349 15.3436C125.741 15.3436 126.737 18.9131 126.737 23.5536V33.1207H121.368V24.5687C121.39 22.5794 121.355 20.0249 118.615 20.0249C115.841 20.0249 115.421 22.189 115.455 24.4255V32.9311H110.082V6.10547H115.445Z"
        fill="white"
      />
      <path
        d="M103.427 20.8447C103.061 20.378 102.592 20.0032 102.055 19.7501C101.519 19.497 100.932 19.3727 100.339 19.3871C99.4503 19.3871 98.387 19.7775 98.387 20.8094C98.387 23.3638 107.37 21.1998 107.37 27.7682C107.37 32.1688 103.114 33.4478 99.4131 33.4478C97.0076 33.4478 94.3362 32.8455 92.6333 31.1053L95.8624 27.5153C96.857 28.7219 98.03 29.3968 99.6269 29.3968C100.833 29.3968 102.044 29.077 102.044 28.1549C102.044 25.3791 93.0627 27.7291 93.0627 21.1607C93.0627 17.1115 96.6822 15.3379 100.313 15.3379C102.585 15.3379 105.104 15.8956 106.702 17.6098L103.427 20.8447Z"
        fill="white"
      />
      <path
        d="M85.2899 30.8993H85.2174C84.0239 32.8012 81.8545 33.5634 79.6404 33.5634C76.3518 33.5634 73.3848 31.6597 73.3848 28.0882C73.3848 21.953 80.7632 21.8434 85.2825 21.8434C85.2825 19.908 83.6912 18.6679 81.8098 18.6679C80.0382 18.6679 78.5566 19.4711 77.3984 20.7855L74.5188 17.7923C76.5098 15.8904 79.4024 14.9385 82.2579 14.9385C88.5785 14.9385 90.2851 18.1864 90.2851 24.1022V33.1191H85.2899V30.8993ZM84.1615 25.5338C82.5274 25.5338 78.9079 25.6751 78.9079 28.0176C78.9079 29.2967 80.2557 29.9009 81.4269 29.9009C83.522 29.9009 85.4758 28.7984 85.4758 26.6697V25.5338H84.1615Z"
        fill="white"
      />
      <path
        d="M65.4932 6.10547H70.8602V32.9348H65.4932V6.10547Z"
        fill="white"
      />
      <path
        d="M45.1426 7.77148H61.9797V12.9511H50.6936V18.1325H61.238V23.314H50.6936V33.1191H45.1426V7.77148Z"
        fill="white"
      />
      <path
        d="M16.1103 33.1191H1.46677C0.656694 33.1191 0 33.7759 0 34.586V40.5334C0 41.3436 0.656694 42.0003 1.46677 42.0003H16.1103C16.9203 42.0003 17.577 41.3436 17.577 40.5334V34.586C17.577 33.7759 16.9203 33.1191 16.1103 33.1191Z"
        fill="white"
      />
      <path
        d="M16.1103 11.1006H1.46677C0.656694 11.1006 0 11.7573 0 12.5675V18.5149C0 19.325 0.656694 19.9817 1.46677 19.9817H16.1103C16.9203 19.9817 17.577 19.325 17.577 18.5149V12.5675C17.577 11.7573 16.9203 11.1006 16.1103 11.1006Z"
        fill="white"
      />
      <path
        d="M33.6855 22.0176H5.72214C4.91207 22.0176 4.25537 22.6743 4.25537 23.4844V29.4319C4.25537 30.242 4.91207 30.8987 5.72214 30.8987H33.6855C34.4956 30.8987 35.1523 30.242 35.1523 29.4319V23.4844C35.1523 22.6743 34.4956 22.0176 33.6855 22.0176Z"
        fill="white"
      />
      <path
        d="M33.6855 0H5.72214C4.91207 0 4.25537 0.656739 4.25537 1.46687V7.41428C4.25537 8.22441 4.91207 8.88115 5.72214 8.88115H33.6855C34.4956 8.88115 35.1523 8.22441 35.1523 7.41428V1.46687C35.1523 0.656739 34.4956 0 33.6855 0Z"
        fill="white"
      />
    </svg>
  ) : type === "flashpay" ? (
    <svg
      height="32"
      width="27"
      fill="none"
      viewBox="0 0 27 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.732 0.285645H4.62038C4.00862 0.285645 3.5127 0.781571 3.5127 1.39333V5.88272C3.5127 6.49448 4.00862 6.99041 4.62038 6.99041H25.732C26.3438 6.99041 26.8397 6.49448 26.8397 5.88272V1.39333C26.8397 0.781571 26.3438 0.285645 25.732 0.285645Z"
        fill="#006174"
      />
      <path
        d="M25.732 16.9077H4.62038C4.00862 16.9077 3.5127 17.4036 3.5127 18.0154V22.5048C3.5127 23.1166 4.00862 23.6125 4.62038 23.6125H25.732C26.3438 23.6125 26.8397 23.1166 26.8397 22.5048V18.0154C26.8397 17.4036 26.3438 16.9077 25.732 16.9077Z"
        fill="#006174"
      />
      <path
        d="M12.3226 8.52686H1.26808C0.656327 8.52686 0.1604 9.02278 0.1604 9.63454V14.1239C0.1604 14.7357 0.656327 15.2316 1.26808 15.2316H12.3226C12.9343 15.2316 13.4302 14.7357 13.4302 14.1239V9.63454C13.4302 9.02278 12.9343 8.52686 12.3226 8.52686Z"
        fill="#006174"
      />
      <path
        d="M12.3226 25.1489H1.26808C0.656327 25.1489 0.1604 25.6449 0.1604 26.2566V30.746C0.1604 31.3578 0.656327 31.8537 1.26808 31.8537H12.3226C12.9343 31.8537 13.4302 31.3578 13.4302 30.746V26.2566C13.4302 25.6449 12.9343 25.1489 12.3226 25.1489Z"
        fill="#006174"
      />
    </svg>
  ) : type === "notificationBell" ? (
    <svg
      height="23"
      width="18"
      fill="none"
      viewBox="0 0 18 23"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.02009 2.91C5.71009 2.91 3.02009 5.6 3.02009 8.91V11.8C3.02009 12.41 2.76009 13.34 2.45009 13.86L1.30009 15.77C0.590088 16.95 1.08009 18.26 2.38009 18.7C6.69009 20.14 11.3401 20.14 15.6501 18.7C16.8601 18.3 17.3901 16.87 16.7301 15.77L15.5801 13.86C15.2801 13.34 15.0201 12.41 15.0201 11.8V8.91C15.0201 5.61 12.3201 2.91 9.02009 2.91Z"
        stroke="#6D6D6D"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <path
        d="M10.8699 3.2C10.5599 3.11 10.2399 3.04 9.90992 3C8.94992 2.88 8.02992 2.95 7.16992 3.2C7.45992 2.46 8.17992 1.94 9.01992 1.94C9.85992 1.94 10.5799 2.46 10.8699 3.2Z"
        stroke="#6D6D6D"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <path
        d="M12.02 19.06C12.02 20.71 10.67 22.06 9.02002 22.06C8.20002 22.06 7.44002 21.72 6.90002 21.18C6.36002 20.64 6.02002 19.88 6.02002 19.06"
        stroke="#6D6D6D"
        strokeWidth="1.5"
      />
      <circle cx="13.5" cy="4.5" fill="#EB5757" r="4.5" />
    </svg>
  ) : type === "generatelink" ? (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5 12C13.5 15 12 17.5 8.99995 17.75M2 14C0.999985 10 4.57 6.25 7.75 6.25"
        stroke="white"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <path
        d="M10 12C10 8.69 12.69 6 16 6C19.31 6 22 8.69 22 12C22 15.31 19.31 18 16 18"
        stroke="white"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <path
        d="M9 17C9 17.75 8.79 18.46 8.42 19.06C8.21 19.42 7.94 19.74 7.63 20C6.93 20.63 6.01 21 5 21C3.54 21 2.27 20.22 1.58 19.06C1.21 18.46 1 17.75 1 17C1 15.74 1.58 14.61 2.5 13.88C3.19 13.33 4.06 13 5 13C7.21 13 9 14.79 9 17Z"
        stroke="white"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <path
        d="M6.49171 16.9795H3.51172"
        stroke="white"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <path
        d="M5 15.5195V18.5095"
        stroke="white"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
    </svg>
  ) : type === "filter" ? (
    <svg
      height="22"
      width="20"
      fill="none"
      viewBox="0 0 20 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.3999 1.1001H16.5999C17.6999 1.1001 18.5999 2.0001 18.5999 3.1001V5.3001C18.5999 6.1001 18.0999 7.1001 17.5999 7.6001L13.2999 11.4001C12.6999 11.9001 12.2999 12.9001 12.2999 13.7001V18.0001C12.2999 18.6001 11.8999 19.4001 11.3999 19.7001L9.9999 20.6001C8.6999 21.4001 6.8999 20.5001 6.8999 18.9001V13.6001C6.8999 12.9001 6.4999 12.0001 6.0999 11.5001L2.2999 7.5001C1.7999 7.0001 1.3999 6.1001 1.3999 5.5001V3.2001C1.3999 2.0001 2.2999 1.1001 3.3999 1.1001Z"
        stroke="#006174"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <path
        d="M8.93 1.1001L4 9.0001"
        stroke="#006174"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
    </svg>
  ) : type === "export" ? (
    <svg
      height="15"
      width="16"
      fill="none"
      viewBox="0 0 16 15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.66675 6.83337L14.1334 1.3667"
        stroke="#006174"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <path
        d="M14.6666 4.03325V0.833252H11.4666"
        stroke="#006174"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <path
        d="M7.33325 0.833252H5.99992C2.66659 0.833252 1.33325 2.16659 1.33325 5.49992V9.49992C1.33325 12.8333 2.66659 14.1666 5.99992 14.1666H9.99992C13.3333 14.1666 14.6666 12.8333 14.6666 9.49992V8.16659"
        stroke="#006174"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
    </svg>
  ) : type === "copy" ? (
    <svg
      height="22"
      width="22"
      fill="none"
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 11.9V16.1C15 19.6 13.6 21 10.1 21H5.9C2.4 21 1 19.6 1 16.1V11.9C1 8.4 2.4 7 5.9 7H10.1C13.6 7 15 8.4 15 11.9Z"
        stroke="#6D6D6D"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <path
        d="M21 5.9V10.1C21 13.6 19.6 15 16.1 15H15V11.9C15 8.4 13.6 7 10.1 7H7V5.9C7 2.4 8.4 1 11.9 1H16.1C19.6 1 21 2.4 21 5.9Z"
        stroke="#6D6D6D"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
    </svg>
  ) : type === "tickcircle" ? (
    <svg
      height="14"
      width="14"
      fill="none"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00016 0.333008C3.32683 0.333008 0.333496 3.32634 0.333496 6.99967C0.333496 10.673 3.32683 13.6663 7.00016 13.6663C10.6735 13.6663 13.6668 10.673 13.6668 6.99967C13.6668 3.32634 10.6735 0.333008 7.00016 0.333008ZM10.1868 5.46634L6.40683 9.24634C6.3135 9.33967 6.18683 9.39301 6.0535 9.39301C5.92016 9.39301 5.7935 9.33967 5.70016 9.24634L3.8135 7.35967C3.62016 7.16634 3.62016 6.84634 3.8135 6.65301C4.00683 6.45967 4.32683 6.45967 4.52016 6.65301L6.0535 8.18634L9.48016 4.75967C9.6735 4.56634 9.9935 4.56634 10.1868 4.75967C10.3802 4.95301 10.3802 5.26634 10.1868 5.46634Z"
        fill="#1dba84"
      />
    </svg>
  ) : type === "unchecked" ? (
    <svg
      height="14"
      width="14"
      fill="none"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7.00016" cy="6.99967" fill="#F2F2F2" r="6.66667" />
    </svg>
  ) : type === "download" ? (
    <svg
      height="22"
      width="23"
      fill="none"
      viewBox="0 0 23 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 10V16L10.5 14"
        stroke="white"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <path
        d="M8.5 16L6.5 14"
        stroke="white"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <path
        d="M21.5 9V14C21.5 19 19.5 21 14.5 21H8.5C3.5 21 1.5 19 1.5 14V8C1.5 3 3.5 1 8.5 1H13.5"
        stroke="white"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <path
        d="M21.5 9H17.5C14.5 9 13.5 8 13.5 5V1L21.5 9Z"
        stroke="white"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
    </svg>
  ) : type === "settings" ? (
    <svg
      height="22"
      width="22"
      fill="none"
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 14C12.6569 14 14 12.6569 14 11C14 9.34318 12.6569 8.00003 11 8.00003C9.34315 8.00003 8 9.34318 8 11C8 12.6569 9.34315 14 11 14Z"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <path
        d="M1 11.88V10.12C1 9.08003 1.85 8.22003 2.9 8.22003C4.71 8.22003 5.45 6.94003 4.54 5.37003C4.02 4.47003 4.33 3.30003 5.24 2.78003L6.97 1.79003C7.76 1.32003 8.78 1.60003 9.25 2.39003L9.36 2.58003C10.26 4.15003 11.74 4.15003 12.65 2.58003L12.76 2.39003C13.23 1.60003 14.25 1.32003 15.04 1.79003L16.77 2.78003C17.68 3.30003 17.99 4.47003 17.47 5.37003C16.56 6.94003 17.3 8.22003 19.11 8.22003C20.15 8.22003 21.01 9.07003 21.01 10.12V11.88C21.01 12.92 20.16 13.78 19.11 13.78C17.3 13.78 16.56 15.06 17.47 16.63C17.99 17.54 17.68 18.7 16.77 19.22L15.04 20.21C14.25 20.68 13.23 20.4 12.76 19.61L12.65 19.42C11.75 17.85 10.27 17.85 9.36 19.42L9.25 19.61C8.78 20.4 7.76 20.68 6.97 20.21L5.24 19.22C4.33 18.7 4.02 17.53 4.54 16.63C5.45 15.06 4.71 13.78 2.9 13.78C1.85 13.78 1 12.92 1 11.88Z"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
    </svg>
  ) : null;
};

export default AppIcons;
